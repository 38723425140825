.Graduates {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Intro part */
.Graduates .intro-part {
  position: relative;
  padding: 0;
  margin: 0;
  padding: 50px 0 50px 150px;
  cursor: default;
}
.Graduates .intro-part h2 {
  width: 10%;
  text-align: left;
  font-size: 1.8em;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 30px;
}
.Graduates .intro-part p {
  font-size: 14px;
  color: var(--input-grey-color);
}

/* Posts part */
.Graduates .posts-part {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.Graduates .posts-part .posts-circle {
  width: 28em;
  height: 25em;
  padding: 0;
  margin: 0;
  margin-top: 130px;
  margin-left: 80px;
  position: relative;
}

.Graduates .posts-part .posts-circle .post {
  cursor: default;
  width: 105px;
  height: auto;
  position: absolute;
  padding: 0;
  margin: 0;
  transition: all 0.4s ease-in-out;
}
.Graduates .posts-part .posts-circle .post a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: scale(1);
}
.Graduates .posts-part .posts-circle .post:hover {
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
}
.Graduates .posts-part .posts-circle .post:nth-child(1) {
  left: 40px;
  top: 0;
}
.Graduates .posts-part .posts-circle .post:nth-child(2) {
  right: 50px;
}
.Graduates .posts-part .posts-circle .post:nth-child(3) {
  left: 0;
  bottom: 110px;
}
.Graduates .posts-part .posts-circle .post:nth-child(4) {
  left: 170px;
  bottom: 0;
}
.Graduates .posts-part .posts-circle .post:nth-child(5) {
  right: 0;
  bottom: 140px;
}
.Graduates .posts-part .posts-circle .post img {
  width: 70px;
  height: auto;
  object-fit: cover;
}
.Graduates .posts-part .posts-circle .post h6 {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  margin-top: 8px;
  font-family: "Anton", "Arial", sans-serif;
  text-transform: uppercase;
  color: #5f5f5f;
  text-align: center;
}
.Graduates .posts-part .posts-circle .post p {
  text-transform: capitalize;
  color: var(--red-color) !important;
  font-size: 9px;
  font-weight: 500;
  text-align: center;
  margin: 0;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 200px and down) */
@media only screen and (max-width: 600px) {
  /* Intro part */
  .Graduates .intro-part {
    padding: 70px 0 5px 0;
  }
  .Graduates .intro-part h2 {
    font-size: 1.4em;
  }
  .Graduates .intro-part p {
    font-size: 14px;
  }

  /* Posts part */
  .Graduates .posts-part .posts-circle {
    width: 19em;
    height: 19em;
    margin-left: 0;
    margin: 0 auto;
    margin-top: 10px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(1) {
    left: 30px;
    top: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(2) {
    right: 40px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(3) {
    left: 0;
    bottom: 110px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(4) {
    left: 105px;
    bottom: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(5) {
    right: 0;
    bottom: 110px;
  }
}

/* Small devices (portrait tablets and large phones, 200px and up) */
@media only screen and (min-width: 600px) {
  /* Intro part */
  .Graduates .intro-part {
    padding: 100px 0 10px 0;
  }
  .Graduates .intro-part h2 {
    font-size: 1.6em;
  }
  .Graduates .intro-part p {
    font-size: 15px;
  }

  /* Posts part */
  .Graduates .posts-part .posts-circle {
    width: 26em;
    height: 22em;
    margin-left: 0;
    margin: 0 auto;
    margin-top: 10px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(1) {
    left: 30px;
    top: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(2) {
    right: 40px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(3) {
    left: 0;
    bottom: 130px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(4) {
    left: 150px;
    bottom: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(5) {
    right: 0;
    bottom: 140px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* Intro part */
  .Graduates .intro-part {
    padding: 120px 0 5px 0;
  }
  .Graduates .intro-part h2 {
    font-size: 1.6em;
  }
  .Graduates .intro-part p {
    font-size: 16px;
  }

  /* Posts part */
  .Graduates .posts-part .posts-circle {
    width: 26em;
    height: 22em;
    margin-left: 0;
    margin: 0 auto;
    margin-top: 10px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(1) {
    left: 30px;
    top: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(2) {
    right: 40px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(3) {
    left: 0;
    bottom: 130px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(4) {
    left: 150px;
    bottom: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(5) {
    right: 0;
    bottom: 140px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* Intro part */
  .Graduates .intro-part {
    padding: 130px 0 50px 130px;
  }
  .Graduates .intro-part h2 {
    font-size: 1.8em;
  }
  .Graduates .intro-part p {
    font-size: 14px;
  }

  /* Posts part */
  .Graduates .posts-part .posts-circle {
    width: 28em;
    height: 25em;
    margin-top: 130px;
    margin-left: 80px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(1) {
    left: 40px;
    top: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(2) {
    right: 50px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(3) {
    left: 0;
    bottom: 110px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(4) {
    left: 170px;
    bottom: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(5) {
    right: 0;
    bottom: 140px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* Intro part */
  .Graduates .intro-part {
    padding: 50px 0 50px 150px;
  }
  .Graduates .intro-part h2 {
    font-size: 1.8em;
  }
  .Graduates .intro-part p {
    font-size: 14px;
  }

  /* Posts part */
  .Graduates .posts-part .posts-circle {
    width: 28em;
    height: 25em;
    margin-top: 130px;
    margin-left: 80px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(1) {
    left: 40px;
    top: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(2) {
    right: 50px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(3) {
    left: 0;
    bottom: 110px;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(4) {
    left: 170px;
    bottom: 0;
  }
  .Graduates .posts-part .posts-circle .post:nth-child(5) {
    right: 0;
    bottom: 140px;
  }
}
