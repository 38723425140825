.Blogs {
  width: 100%;
  height: auto;
  margin-bottom: 15vh;
}

.Blogs .BgView img {
  object-fit: cover;
  object-position: top;
}

/* Cards list */
.Blogs .list-blogs {
  padding: 0;
  width: 50%;
  padding: 0 15px;
  margin: 0 auto;
  cursor: default;
}
.Blogs .list-blogs .item-blog {
  margin-bottom: 65px;
  height: auto;
  border-bottom: 1px solid var(--divider-grey-color);
  padding-bottom: 15px;
  cursor: default;
}
.Blogs .list-blogs .item-blog .part-1 img {
  width: 100px;
  height: 100%;
  position: relative;
  object-fit: cover;
}
.Blogs .list-blogs .item-blog .part-2 {
  text-transform: capitalize;
  padding-left: 15px;
}
.Blogs .list-blogs .item-blog .part-2 .title h6 {
  font-size: 1.4em;
  font-weight: 700;
  margin: 0;
  margin-top: 2px;
}
.Blogs .list-blogs .item-blog .part-2 .title p {
  font-size: 0.8em;
  margin: 3px 0 12px;
  color: var(--grey-color);
}
.Blogs .list-blogs .item-blog .part-2 .infos .more-infos,
.Blogs .list-blogs .item-blog .part-2 .infos .date {
  margin: 0;
  color: var(--grey-color);
  font-size: 0.7em;
  padding-right: 20px;
}
.Blogs .list-blogs .item-blog .part-2 .infos .more-infos a {
  text-transform: capitalize;
  color: var(--red-color) !important;
  text-decoration: underline !important;
  margin-left: 5px;
  transition: all 0.4s ease-in-out;
}
.Blogs .list-blogs .item-blog .part-2 .infos .more-infos a:hover {
  text-decoration: none !important;
  transition: all 0.4s ease-in-out;
}
.Blogs .list-blogs .item-blog .part-2 .infos .date {
  padding: 0;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Blogs {
    margin-bottom: 12vh;
  }
  .Blogs .list-blogs {
    width: 100%;
    padding: 0 25px;
  }

  .Blogs .list-blogs .item-blog {
    margin-bottom: 50px;
  }
  .Blogs .list-blogs .item-blog .part-1 img {
    width: 100%;
  }
  .Blogs .list-blogs .item-blog .part-2 .title h6,
  .Blogs .list-blogs .item-blog .part-2 .infos .date {
    margin-top: 10px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Blogs {
    margin-bottom: 12vh;
  }
  .Blogs .list-blogs {
    width: 100%;
    padding: 0 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Blogs {
    margin-bottom: 13vh;
  }
  .Blogs .list-blogs {
    width: 100%;
    padding: 0 30px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Blogs {
    margin-bottom: 14vh;
  }
  .Blogs .list-blogs {
    width: 80%;
    padding: 0 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .Blogs {
    margin-bottom: 15vh;
  }
  .Blogs .list-blogs {
    width: 50%;
    padding: 0 15px;
  }

  .Blogs .list-blogs .item-blog {
    margin-bottom: 65px;
  }
  .Blogs .list-blogs .item-blog .part-1 img {
    width: 100px;
  }
  .Blogs .list-blogs .item-blog .part-2 .title h6,
  .Blogs .list-blogs .item-blog .part-2 .infos .date {
    margin-top: 2px;
  }
}
