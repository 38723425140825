.FooterPart {
  background-color: var(--black-color);
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 18%;
  height: auto;
  cursor: default;
}
.FooterPart h3 {
  font-family: "Barlow", "Arial", sans-serif;
  color: var(--white-color);
  font-weight: bold;
  font-size: 4.5rem;
  margin-bottom: 25px;
  text-transform: uppercase;
  width: 70%;
}
.FooterPart p {
  color: var(--white-color);
  font-size: 1.1rem;
}

.FooterPart .imgs {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.FooterPart .imgs img {
  width: 45%;
  height: auto;
}
.FooterPart .imgs img:first-child {
  margin-top: -90px;
}
.FooterPart .imgs img:last-child {
  margin-top: 90px;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .FooterPart {
    padding: 15% 8%;
  }
  .FooterPart h3 {
    font-size: 3rem;
    width: 100%;
  }
  .FooterPart p {
    font-size: 1.1rem;
  }
  .FooterPart .imgs {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
  .FooterPart .imgs img {
    width: 90%;
  }
  .FooterPart .imgs img:first-child {
    margin-top: 0;
    align-self: start;
  }
  .FooterPart .imgs img:last-child {
    margin-top: 20px;
    align-self: end;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .FooterPart {
    padding: 15% 8%;
  }
  .FooterPart h3 {
    font-size: 3rem;
    width: 100%;
  }
  .FooterPart p {
    font-size: 1.1rem;
  }
  .FooterPart .imgs {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .FooterPart .imgs img {
    width: 45%;
  }
  .FooterPart .imgs img:first-child {
    margin-top: -90px;
  }
  .FooterPart .imgs img:last-child {
    margin-top: 90px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .FooterPart {
    padding: 10% 15%;
  }
  .FooterPart h3 {
    font-size: 3.5rem;
    width: 100%;
  }
  .FooterPart p {
    font-size: 1.2rem;
  }
  .FooterPart .imgs {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .FooterPart .imgs img {
    width: 45%;
  }
  .FooterPart .imgs img:first-child {
    margin-top: -90px;
  }
  .FooterPart .imgs img:last-child {
    margin-top: 90px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .FooterPart {
    padding: 8% 15%;
  }
  .FooterPart h3 {
    font-size: 3.5rem;
    width: 100%;
  }
  .FooterPart p {
    font-size: 1.2rem;
  }
  .FooterPart .imgs {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .FooterPart .imgs img {
    width: 45%;
  }
  .FooterPart .imgs img:first-child {
    margin-top: -90px;
  }
  .FooterPart .imgs img:last-child {
    margin-top: 90px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .FooterPart {
    padding: 5% 18%;
  }
  .FooterPart h3 {
    font-size: 4.5rem;
    width: 70%;
  }
  .FooterPart p {
    font-size: 1.1rem;
  }
  .FooterPart .imgs {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .FooterPart .imgs img {
    width: 45%;
  }
  .FooterPart .imgs img:first-child {
    margin-top: -90px;
  }
  .FooterPart .imgs img:last-child {
    margin-top: 90px;
  }
}
