.Carousel {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-color: var(--white-color);
}
.Carousel .swiper {
  width: 100%;
  height: 100%;
  cursor: default;
}

/* Custom navigation */
.Carousel .swiper .myNavigation {
  position: absolute;
  bottom: 30px;
  right: 80px;
  height: auto;
  width: auto;
  z-index: 100;
}
.Carousel .swiper .myNavigation .prev,
.Carousel .swiper .myNavigation .next {
  color: var(--grey-color);
  font-size: 35px;
  margin: 0 10px;
  opacity: 0.6;
  cursor: pointer;
}
.Carousel .swiper .myNavigation .prev.disabled,
.Carousel .swiper .myNavigation .next.disabled {
  opacity: 0.2;
}

/* Main slide */
.Carousel .main-swiper .swiper-slide:nth-child(1) {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  padding-top: 60px;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .row {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .content {
  padding: 40px;
  padding-left: 100px;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
  font-weight: 600;
  font-size: 2.9em;
  text-align: left;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle,
.Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
  font-weight: 500;
  font-size: 1.7em;
  text-align: left;
  text-transform: capitalize;
  display: block;
  margin-bottom: 20px;
  width: 100%;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
  display: none;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
  width: 70%;
  margin: 0;
}
.Carousel
  .main-swiper
  .swiper-slide:nth-child(1)
  .video
  .video-react
  .video-react-poster {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.Carousel
  .main-swiper
  .swiper-slide:nth-child(1)
  .content
  .video
  .video-react
  .video-react-button.video-react-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid var(--white-color);
}
.Carousel
  .main-swiper
  .swiper-slide:nth-child(1)
  .content
  .video
  .video-react
  .video-react-big-play-button.video-react-big-play-button:before {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
  font-weight: 500;
  font-size: 0.9em;
  text-align: justify;
  width: 70%;
  margin-bottom: 0;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
  width: 100%;
  height: 100%;
  margin-left: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li {
  text-align: left;
  padding: 0;
  transition: all 0.4s ease-in-out;
  text-transform: uppercase;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li:hover {
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
  color: var(--dark-red-color) !important;
  font-weight: 400;
  font-size: 1em;
}
.Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li:hover a {
  color: var(--black-color) !important;
}

/* Other slides */
.Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
  height: 50vh;
  width: 45% !important;
  position: relative;
  margin: auto 0;
}
.Carousel .main-swiper .swiper-slide:not(:nth-child(1)) img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 5;
}
.Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
  position: absolute;
  color: #ff3e32;
  font-size: 4vw;
  font-family: "Anton", "Arial", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  bottom: -2.5vw;
  z-index: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: center;
  z-index: 10;
}
.Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
  font-family: "TheinhardtReg", "Arial", sans-serif;
  letter-spacing: 2%;
  line-height: 25px;
  font-size: 1.2vw;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0;
  width: 100%;
  padding: 0 15%;
  position: absolute;
  bottom: -110px;
  z-index: 10;
  cursor: default;
}
.Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details a {
  text-decoration: none;
  color: var(--dark-red-color) !important;
  font-style: italic;
  text-transform: capitalize;
  margin-left: 5px;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* Main slide */
  .Carousel .main-swiper .swiper-slide:nth-child(1) .row {
    justify-content: flex-start;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content {
    padding-bottom: 5px;
    padding-left: 40px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
    font-size: 1.4em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle {
    display: none;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
    margin-bottom: 20px;
    font-size: 1.4em;
    display: block;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
    width: 100%;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
    font-size: 0.8em;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    padding-left: 1.5rem;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li {
    width: 45%;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
    font-size: 0.9em;
  }

  /* Other slides */
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
    height: 25vh;
    width: 80% !important;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
    font-size: 8vw;
    bottom: -5.5vw;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
    font-size: 4.2vw;
    padding: 0;
    bottom: -140px;
  }

  /* Custom navigation */
  .Carousel .swiper .myNavigation {
    bottom: 10px;
    right: 30px;
  }
  .Carousel .swiper .myNavigation .prev,
  .Carousel .swiper .myNavigation .next {
    font-size: 35px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* Main slide */
  .Carousel .main-swiper .swiper-slide:nth-child(1) .row {
    justify-content: flex-start;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content {
    padding-bottom: 5px;
    padding-left: 40px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
    font-size: 1.4em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle {
    margin-bottom: 20px;
    font-size: 1.4em;
    display: block;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
    display: none;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
    width: 85%;
    margin: 0 auto;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
    font-size: 1em;
    text-align: center;
    width: 100%;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0;
    width: 100%;
    padding-left: 1.5rem;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li {
    width: 45%;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
    font-size: 0.9em;
  }

  /* Other slides */
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
    height: 40vh;
    width: 75% !important;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
    font-size: 6vw;
    bottom: -3.5vw;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
    font-size: 2.4vw;
    padding: 0 5%;
    bottom: -120px;
  }
  /* Custom navigation */
  .Carousel .swiper .myNavigation {
    bottom: 10px;
    right: 30px;
  }
  .Carousel .swiper .myNavigation .prev,
  .Carousel .swiper .myNavigation .next {
    font-size: 35px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* Main slide */
  .Carousel .main-swiper .swiper-slide:nth-child(1) .row {
    justify-content: center;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content {
    padding-bottom: 5px;
    padding-left: 40px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
    font-size: 1.4em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle {
    margin-bottom: 20px;
    font-size: 1.4em;
    display: block;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
    display: none;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
    width: 60%;
    margin: 0 auto;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
    font-size: 1em;
    text-align: center;
    width: 100%;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0;
    width: 100%;
    padding-left: 1.5rem;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li {
    width: 45%;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
    font-size: 0.9em;
  }

  /* Other slides */
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
    height: 50vh;
    width: 75% !important;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
    font-size: 5.5vw;
    bottom: -3vw;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
    font-size: 2.1vw;
    padding: 0 10%;
    bottom: -120px;
  }

  /* Custom navigation */
  .Carousel .swiper .myNavigation {
    bottom: 15px;
    right: 40px;
  }
  .Carousel .swiper .myNavigation .prev,
  .Carousel .swiper .myNavigation .next {
    font-size: 35px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* Main slide */
  .Carousel .main-swiper .swiper-slide:nth-child(1) .row {
    justify-content: center;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content {
    padding-bottom: 5px;
    padding-left: 100px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
    font-size: 3.3em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle {
    margin-bottom: 20px;
    font-size: 2em;
    display: block;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
    display: none;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
    width: 80%;
    margin: 0;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
    font-size: 1.2em;
    text-align: justify;
    width: 80%;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
    width: 100%;
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 15px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li {
    width: 45%;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
    font-size: 1.2em;
  }

  /* Other slides */
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
    height: 50vh;
    width: 50% !important;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
    font-size: 5vw;
    bottom: -2.9vw;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
    font-size: 1.3vw;
    padding: 0 60px;
    bottom: -120px;
  }

  /* Custom navigation */
  .Carousel .swiper .myNavigation {
    bottom: 15px;
    right: 40px;
  }
  .Carousel .swiper .myNavigation .prev,
  .Carousel .swiper .myNavigation .next {
    font-size: 45px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* Main slide */
  .Carousel .main-swiper .swiper-slide:nth-child(1) .row {
    justify-content: center;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content {
    padding: 40px;
    padding-left: 100px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
    font-size: 2.9em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle {
    font-size: 1.7em;
    display: block;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
    display: none;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
    width: 75%;
    margin: 0;
  }
  .Carousel
    .main-swiper
    .swiper-slide:nth-child(1)
    .content
    .video
    .video-react
    .video-react-button.video-react-big-play-button {
    width: 3em;
    height: 3em;
    border: 4px solid var(--white-color);
  }
  .Carousel
    .main-swiper
    .swiper-slide:nth-child(1)
    .content
    .video
    .video-react
    .video-react-big-play-button.video-react-big-play-button:before {
    font-size: 2em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
    font-size: 0.9em;
    text-align: justify;
    width: 75%;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
    width: 100%;
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
    font-size: 1em;
  }

  /* Other slides */
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
    height: 50vh;
    width: 45% !important;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
    font-size: 4vw;
    bottom: -2.5vw;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
    font-size: 1.2vw;
    padding: 0 15%;
    bottom: -110px;
  }

  /* Custom navigation */
  .Carousel .swiper .myNavigation {
    bottom: 30px;
    right: 80px;
  }
  .Carousel .swiper .myNavigation .prev,
  .Carousel .swiper .myNavigation .next {
    font-size: 35px;
  }
}

/* Mac and iPad resolutions */
@media only screen and (min-width: 2000px) {
  /* Main slide */
  .Carousel .main-swiper .swiper-slide:nth-child(1) .row {
    justify-content: center;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content {
    padding: 40px;
    padding-left: 150px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
    font-size: 3.8em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle {
    font-size: 2.5em;
    display: block;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
    display: none;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
    width: 80%;
    margin: 0;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
    font-size: 1.3em;
    text-align: justify;
    width: 80%;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
    width: 100%;
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
    font-size: 1.4em;
  }

  /* Other slides */
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
    height: 50vh;
    width: 45% !important;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
    font-size: 4vw;
    bottom: -2.5vw;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
    font-size: 1.2vw;
    padding: 0 15%;
    bottom: -110px;
  }

  /* Custom navigation */
  .Carousel .swiper .myNavigation {
    bottom: 30px;
    right: 80px;
  }
  .Carousel .swiper .myNavigation .prev,
  .Carousel .swiper .myNavigation .next {
    font-size: 45px;
  }
}
@media only screen and (min-width: 2500px) {
  /* Main slide */
  .Carousel .main-swiper .swiper-slide:nth-child(1) .row {
    justify-content: center;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content {
    padding: 40px;
    padding-left: 200px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
    font-size: 5em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle {
    font-size: 3.2em;
    display: block;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
    display: none;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
    width: 75%;
    margin: 0;
  }
  .Carousel
    .main-swiper
    .swiper-slide:nth-child(1)
    .content
    .video
    .video-react
    .video-react-button.video-react-big-play-button {
    width: 5em;
    height: 5em;
    border: 7px solid var(--white-color);
  }
  .Carousel
    .main-swiper
    .swiper-slide:nth-child(1)
    .content
    .video
    .video-react
    .video-react-big-play-button.video-react-big-play-button:before {
    font-size: 3em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
    font-size: 1.8em;
    text-align: justify;
    width: 75%;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
    width: 100%;
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 25px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
    font-size: 1.9em;
  }

  /* Other slides */
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
    height: 50vh;
    width: 45% !important;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
    font-size: 4vw;
    bottom: -2.5vw;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
    font-size: 1.2vw;
    padding: 0 15%;
    bottom: -190px;
    line-height: 45px;
  }

  /* Custom navigation */
  .Carousel .swiper .myNavigation {
    bottom: 30px;
    right: 80px;
  }
  .Carousel .swiper .myNavigation .prev,
  .Carousel .swiper .myNavigation .next {
    font-size: 60px;
  }
}
@media only screen and (min-width: 2800px) {
  /* Main slide */
  .Carousel .main-swiper .swiper-slide:nth-child(1) .row {
    justify-content: center;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content {
    padding: 40px;
    padding-left: 250px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
    font-size: 8em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle {
    font-size: 5em;
    display: block;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
    display: none;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
    width: 75%;
    margin: 50px 0;
  }
  .Carousel
    .main-swiper
    .swiper-slide:nth-child(1)
    .content
    .video
    .video-react
    .video-react-button.video-react-big-play-button {
    width: 7em;
    height: 7em;
    border: 9px solid var(--white-color);
  }
  .Carousel
    .main-swiper
    .swiper-slide:nth-child(1)
    .content
    .video
    .video-react
    .video-react-big-play-button.video-react-big-play-button:before {
    font-size: 5em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
    font-size: 2.6em;
    text-align: justify;
    width: 75%;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
    width: 100%;
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 35px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
    font-size: 3em;
  }

  /* Other slides */
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
    height: 50vh;
    width: 45% !important;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
    font-size: 4vw;
    bottom: -2.5vw;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
    font-size: 1.2vw;
    padding: 0 15%;
    bottom: -290px;
    line-height: 65px;
  }

  /* Custom navigation */
  .Carousel .swiper .myNavigation {
    bottom: 30px;
    right: 80px;
  }
  .Carousel .swiper .myNavigation .prev,
  .Carousel .swiper .myNavigation .next {
    font-size: 110px;
  }
}
@media only screen and (min-width: 5000px) {
  /* Main slide */
  .Carousel .main-swiper .swiper-slide:nth-child(1) .row {
    justify-content: center;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content {
    padding: 40px;
    padding-left: 300px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .title {
    font-size: 11em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle {
    font-size: 6.5em;
    display: block;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .subTitle-mobile {
    display: none;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content .video {
    width: 65%;
    margin: 70px 0;
  }
  .Carousel
    .main-swiper
    .swiper-slide:nth-child(1)
    .content
    .video
    .video-react
    .video-react-button.video-react-big-play-button {
    width: 8em;
    height: 8em;
    border: 12px solid var(--white-color);
  }
  .Carousel
    .main-swiper
    .swiper-slide:nth-child(1)
    .content
    .video
    .video-react
    .video-react-big-play-button.video-react-big-play-button:before {
    font-size: 6em;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .content p {
    font-size: 3.5em;
    text-align: justify;
    width: 65%;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul {
    width: 100%;
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 45px;
  }
  .Carousel .main-swiper .swiper-slide:nth-child(1) .links ul li a {
    font-size: 4em;
  }

  /* Other slides */
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) {
    height: 50vh;
    width: 45% !important;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .title {
    font-size: 4vw;
    bottom: -2.2vw;
  }
  .Carousel .main-swiper .swiper-slide:not(:nth-child(1)) .details {
    font-size: 1.2vw;
    padding: 0 12%;
    bottom: -350px;
    line-height: 80px;
  }

  /* Custom navigation */
  .Carousel .swiper .myNavigation {
    bottom: 30px;
    right: 80px;
  }
  .Carousel .swiper .myNavigation .prev,
  .Carousel .swiper .myNavigation .next {
    font-size: 140px;
  }
}
