.LeftPartBlog {
  height: auto;
  padding: 35px;
  cursor: default;
}

/* First Part */
.LeftPartBlog .part-1 {
  margin-bottom: 150px;
}
.LeftPartBlog .part-1 h6,
.LeftPartBlog .part-2 h6 {
  text-transform: capitalize;
  font-size: 1.1rem;
}
.LeftPartBlog .part-1 h6 {
  font-size: 0.9em;
}
.LeftPartBlog .part-1 h4 {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 2.5rem;
  margin-bottom: 20px;
}
.LeftPartBlog .part-1 p {
  text-decoration: underline !important;
  color: var(--brouwn-color) !important;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  margin-top: 80px;
}
.LeftPartBlog .part-1 p:hover {
  text-decoration: none !important;
  transition: all 0.4s ease-in-out;
}

/* Second Part */
.LeftPartBlog .part-2 h6 {
  color: var(--black-color);
  font-weight: 600;
  margin: 20px 0;
}
.LeftPartBlog .part-2 ul {
  margin: 0;
  padding: 0;
}
.LeftPartBlog .part-2 ul li a {
  color: var(--brouwn-color) !important;
  text-decoration: underline !important;
  font-size: 1em;
  margin-bottom: 5px;
  transition: all 0.4s ease-in-out;
}
.LeftPartBlog .part-2 ul li:hover a {
  text-decoration: none !important;
  transition: all 0.4s ease-in-out;
}

/* Modal Part */
.social-share .modal-dialog .modal-content .modal-body p {
  margin-bottom: 20px;
}
.social-share
  .modal-dialog
  .modal-content
  .modal-body
  .react-share__ShareButton {
  filter: grayscale(0);
  transition: 0.2s ease-in-out;
}
.social-share
  .modal-dialog
  .modal-content
  .modal-body
  .react-share__ShareButton:hover {
  filter: grayscale(1);
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .LeftPartBlog {
    padding: 25px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .LeftPartBlog {
    padding: 30px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .LeftPartBlog {
    padding: 25px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .LeftPartBlog {
    padding: 35px;
  }
}
