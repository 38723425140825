.BgView {
  width: 100%;
  height: 85vh;
  position: relative;
  margin-bottom: 15vh;
  cursor: default;
}

.BgView h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 10px #000;
  width: 90%;
  text-align: center;
  text-transform: uppercase;
  z-index: 3;
}
.BgView img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 5;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.BgView .layer {
  background-color: rgb(0 0 0 / 63%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.BgView h1 {
  position: absolute;
  color: #ff3e32;
  font-size: 5vw;
  font-family: "Anton", "Arial", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  bottom: -8vh;
  z-index: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  z-index: 10;
  padding: 15px;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .BgView {
    height: 45vh;
    margin-bottom: 15vh;
  }
  .BgView h4 {
    font-size: 1.6rem;
  }
  .BgView h1 {
    font-size: 10vw;
    bottom: -6vh;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .BgView {
    height: 50vh;
  }
  .BgView h4 {
    font-size: 2.8rem;
  }
  .BgView h1 {
    font-size: 8vw;
    bottom: -7vh;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .BgView {
    height: 65vh;
  }
  .BgView h4 {
    font-size: 3.5rem;
  }
  .BgView h1 {
    font-size: 6vw;
    bottom: -7vh;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .BgView {
    height: 85vh;
    margin-bottom: 15vh;
  }
  .BgView h4 {
    font-size: 3.5rem;
  }
  .BgView h1 {
    font-size: 5vw;
    bottom: -8vh;
  }
}
