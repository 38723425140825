.About {
  width: 100%;
  height: auto;
}

.About .content {
  width: 50%;
  padding: 0 15px;
  margin: 0 auto;
  cursor: default;
}
.About .content h5 {
  font-size: 1.4em;
}
.About .content p {
  font-size: 1em;
  margin-top: 20px;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .About .content {
    width: 100%;
    padding: 0 15px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .About .content {
    width: 100%;
    padding: 0 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .About .content {
    width: 100%;
    padding: 0 30px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .About .content {
    width: 80%;
    padding: 0 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .About .content {
    width: 50%;
    padding: 0 15px;
  }
}
