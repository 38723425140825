.LeftPartGateway {
  height: auto;
  padding: 35px;
  cursor: default;
}

/* First Part */
.LeftPartGateway .part-1 {
  margin-bottom: 150px;
}
.LeftPartGateway .part-1 h6,
.LeftPartGateway .part-2 h6 {
  text-transform: capitalize;
  font-size: 1.1rem;
}
.LeftPartGateway .part-1 h4 {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 2.5rem;
  margin-bottom: 20px;
}
.LeftPartGateway .part-1 p {
  text-decoration: underline !important;
  color: var(--brouwn-color) !important;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.LeftPartGateway .part-1 p:hover {
  text-decoration: none !important;
  transition: all 0.4s ease-in-out;
}

/* Second Part */
.LeftPartGateway .part-2 p {
  color: var(--grey-color);
  margin: 20px 0;
}
.LeftPartGateway .part-2 .sdg-imgs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: stretch;
  justify-content: flex-start;
  gap: 10px;
}
.LeftPartGateway .part-2 .sdg-imgs img {
  width: 100px;
}

/* Modal Part */
.cv-details-modal {
  overflow: hidden;
  padding-top: 20px;
}
.cv-details-modal .modal-dialog .modal-content {
  height: 90vh;
}
.cv-details-modal .modal-dialog {
  margin: 0;
  margin: 0 auto !important;
}
.cv-details-modal .modal-dialog .modal-content .modal-body {
  overflow-y: scroll;
  margin-right: 10px;
  margin-bottom: 20px;
}
.cv-details-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  height: 8px;
}
.cv-details-modal
  .modal-dialog
  .modal-content
  .modal-body::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius: 30px;
  transition: 0.2s ease-in-out;
}
.cv-details-modal
  .modal-dialog
  .modal-content
  .modal-body::-webkit-scrollbar-thumb:hover {
  background: #dbd9d9;
  transition: 0.2s ease-in-out;
}
.cv-details-modal
  .modal-dialog
  .modal-content
  .modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #39ff8500;
  background-color: transparent;
}
.cv-details-modal .cv-details {
  padding: 40px 80px 0 80px !important;
}
.cv-details-modal .cv-details ul {
  margin: 0;
}
.cv-details-modal .cv-details .titles-part {
  margin-bottom: 60px;
}
.cv-details-modal .cv-details .titles-part h3 {
  color: var(--black-color);
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}
.cv-details-modal .cv-details .titles-part h5 {
  color: var(--light-black-color);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: capitalize;
}
.cv-details-modal .cv-details .list-part {
  color: var(--light-black-color) !important;
}
.cv-details-modal .cv-details .list-part h6 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.02em;
  margin-bottom: 15px;
}
.cv-details-modal .cv-details .list-part ul li {
  list-style: disc;
}
.cv-details-modal .cv-details .list-part ul li::marker {
  font-size: 0.7em;
  color: var(--black-color);
}
.cv-details-modal .cv-details .list-part {
  margin-bottom: 50px;
}
.cv-details-modal .cv-details .list-part:last-child {
  margin-bottom: 0;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .LeftPartGateway {
    padding: 25px;
  }

  /* First Part */
  .LeftPartGateway .part-1 {
    margin-bottom: 80px;
  }

  /* Second Part */
  .part-2 hr {
    width: 80%;
  }
  .LeftPartGateway .part-2 .sdg-imgs {
    gap: 6px;
  }
  .LeftPartGateway .part-2 .sdg-imgs img {
    width: 90px;
  }

  /* Modal Part */
  .cv-details-modal .modal-dialog .modal-content {
    height: 85vh;
  }
  .cv-details-modal .cv-details {
    padding: 10px 5px 0 5px !important;
  }
  .cv-details-modal .cv-details .titles-part h3 {
    font-size: 22px;
  }
  .cv-details-modal .cv-details .titles-part h5 {
    font-size: 16px;
  }
  .cv-details-modal .cv-details .list-part h6 {
    font-size: 19px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .LeftPartGateway {
    padding: 30px;
  }

  /* First Part */
  .LeftPartGateway .part-1 {
    margin-bottom: 100px;
  }

  /* Modal Part */
  .cv-details-modal .modal-dialog .modal-content {
    height: 85vh;
  }
  .cv-details-modal .cv-details {
    padding: 15px 15px 0 15px !important;
  }
  .cv-details-modal .cv-details .titles-part h3 {
    font-size: 24px;
  }
  .cv-details-modal .cv-details .titles-part h5 {
    font-size: 18px;
  }
  .cv-details-modal .cv-details .list-part h6 {
    font-size: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .LeftPartGateway {
    padding: 25px;
  }

  /* Modal Part */
  .cv-details-modal .modal-dialog .modal-content {
    height: 90vh;
  }
  .cv-details-modal .cv-details {
    padding: 40px 80px 0 80px !important;
  }
  .cv-details-modal .cv-details .titles-part h3 {
    font-size: 26px;
  }
  .cv-details-modal .cv-details .titles-part h5 {
    font-size: 18px;
  }
  .cv-details-modal .cv-details .list-part h6 {
    font-size: 22px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .LeftPartGateway {
    padding: 35px;
  }

  /* First Part */
  .LeftPartGateway .part-1 {
    margin-bottom: 150px;
  }

  /* Second Part */
  .part-2 hr {
    width: 100%;
  }
  .LeftPartGateway .part-2 .sdg-imgs {
    gap: 10px;
  }
  .LeftPartGateway .part-2 .sdg-imgs img {
    width: 100px;
  }

  /* Modal Part */
  .cv-details-modal .cv-details {
    padding: 40px 80px 0 80px !important;
  }
  .cv-details-modal .cv-details .titles-part h3 {
    font-size: 26px;
  }
  .cv-details-modal .cv-details .titles-part h5 {
    font-size: 18px;
  }
  .cv-details-modal .cv-details .list-part h6 {
    font-size: 22px;
  }
}