.Register {
  width: 100%;
  height: auto;
  margin-bottom: 15vh;
}

.Register .content {
  padding: 0 80px;
}
/* Register Infos */
.Register .content .infos {
  padding-right: 200px;
}
.Register .content .infos,
.Register .content .form {
  display: flex;
  flex-direction: column;
  justify-content: start-flex;
  align-items: start-flex;
  cursor: default;
}
.Register .content .infos h4 {
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: var(--black-color);
  transition: all 0.4s ease-in-out;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.Register .content .infos p {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  margin: 0;
}

/* Register Form */
.Register .content .form {
  margin-top: 0;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Register {
    margin-bottom: 12vh;
  }
  .Register .content {
    padding: 0 30px;
  }
  .Register .content .infos {
    padding-right: 0;
  }
  .Register .content .form {
    margin-top: 40px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Register {
    margin-bottom: 12vh;
  }
  .Register .content {
    padding: 0 40px;
  }
  .Register .content .infos {
    padding-right: 0;
  }
  .Register .content .form {
    margin-top: 40px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Register {
    margin-bottom: 13vh;
  }
  .Register .content {
    padding: 0 50px;
  }
  .Register .content .infos {
    padding-right: 0;
  }
  .Register .content .form {
    margin-top: 40px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Register {
    margin-bottom: 14vh;
  }
  .Register .content {
    padding: 0 60px;
  }
  .Register .content .infos {
    padding-right: 200px;
  }
  .Register .content .form {
    margin-top: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .Register {
    margin-bottom: 15vh;
  }
  .Register .content {
    padding: 0 80px;
  }
  .Register .content .infos {
    padding-right: 200px;
  }
  .Register .content .form {
    margin-top: 0;
  }
}
