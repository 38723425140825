.RightPartGraduate {
  height: auto;
  padding: 35px 160px 35px 35px;
  cursor: default;
}

.RightPartGraduate p,
.RightPartGraduate .quote p {
  font-size: 17px;
  font-weight: 400;
  color: var(--black-color);
  position: relative;
  margin: 0;
}
.RightPartGraduate .imgs {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.RightPartGraduate .imgs .img-left,
.RightPartGraduate .imgs .img-right {
  width: 40%;
}
.RightPartGraduate .imgs .img-left {
  margin-top: 50px;
}
.RightPartGraduate .imgs .img-right {
  margin-top: -50px;
}

.RightPartGraduate .quote {
  border: 1px solid #ae100f;
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 50px 30px;
  padding: 15px;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .RightPartGraduate {
    padding: 25px;
  }

  .RightPartGraduate p,
  .RightPartGraduate .quote p {
    font-size: 17px;
  }

  .RightPartGraduate .imgs {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .RightPartGraduate .imgs .img-left,
  .RightPartGraduate .imgs .img-right {
    width: 80%;
  }
  .RightPartGraduate .imgs .img-left {
    align-self: end;
  }
  .RightPartGraduate .imgs .img-right {
    margin-top: 0px;
    align-self: start;
  }

  .RightPartGraduate .quote {
    border: none;
    margin: 50px 0;
    padding: 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .RightPartGraduate {
    padding: 30px;
  }

  .RightPartGraduate p,
  .RightPartGraduate .quote p {
    font-size: 18px;
  }

  .RightPartGraduate .imgs {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .RightPartGraduate .imgs .img-left,
  .RightPartGraduate .imgs .img-right {
    width: 80%;
  }
  .RightPartGraduate .imgs .img-left {
    align-self: end;
  }
  .RightPartGraduate .imgs .img-right {
    margin-top: 0px;
    align-self: start;
  }

  .RightPartGraduate .quote {
    border: none;
    margin: 30px 0;
    padding: 10px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .RightPartGraduate p,
  .RightPartGraduate .quote p {
    font-size: 18px;
  }

  .RightPartGraduate .imgs {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .RightPartGraduate .imgs .img-left,
  .RightPartGraduate .imgs .img-right {
    width: 80%;
  }
  .RightPartGraduate .imgs .img-left {
    align-self: end;
  }
  .RightPartGraduate .imgs .img-right {
    margin-top: 0px;
    align-self: start;
  }

  .RightPartGraduate .quote {
    border: none;
    margin: 30px 0;
    padding: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .RightPartGraduate {
    padding: 25px;
  }

  .RightPartGraduate p,
  .RightPartGraduate .quote p {
    font-size: 18px;
  }

  .RightPartGraduate .imgs {
    margin-top: 70px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .RightPartGraduate .imgs .img-left,
  .RightPartGraduate .imgs .img-right {
    width: 40%;
  }
  .RightPartGraduate .imgs .img-right {
    margin-top: -50px;
  }

  .RightPartGraduate .quote {
    border: 1px solid #ae100f;
    margin: 30px 0;
    padding: 10px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .RightPartGraduate {
    padding: 35px 160px 35px 35px;
  }

  .RightPartGraduate p,
  .RightPartGraduate .quote p {
    font-size: 18px;
  }

  .RightPartGraduate .imgs {
    margin-top: 80px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .RightPartGraduate .imgs .img-left,
  .RightPartGraduate .imgs .img-right {
    width: 40%;
  }
  .RightPartGraduate .imgs .img-right {
    margin-top: -50px;
  }

  .RightPartGraduate .quote {
    border: 1px solid #ae100f;
    margin: 50px 30px;
    padding: 15px;
  }
}
