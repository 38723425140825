.RightPartBlog {
  height: auto;
  padding: 35px 160px 35px 35px;
  cursor: default;
}

.RightPartBlog .quote {
  border-left: 1px solid var(--red-color);
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 60px;
  padding: 20px 0 20px 10px;
}
.RightPartBlog .quote svg {
  transform: rotate(180deg);
  color: var(--red-color);
  margin-top: -12px;
}
.RightPartBlog .quote p {
  font-family: "Anton", "Arial", sans-serif;
  font-size: 1.4em;
  margin: 0;
  margin-left: 3px;
}

.RightPartBlog h5 {
  font-size: 1.4em;
  margin-bottom: 20px;
}

.RightPartBlog .imgs {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.RightPartBlog .imgs .img-left,
.RightPartBlog .imgs .img-right {
  width: 40%;
}
.RightPartBlog .imgs .img-left {
  margin-top: 50px;
}
.RightPartBlog .imgs .img-right {
  margin-top: -50px;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .RightPartBlog {
    padding: 25px;
  }

  .RightPartBlog p {
    font-size: 17px;
  }

  .RightPartBlog .imgs {
    margin: 40px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .RightPartBlog .imgs .img-left,
  .RightPartBlog .imgs .img-right {
    width: 80%;
  }
  .RightPartBlog .imgs .img-left {
    align-self: end;
  }
  .RightPartBlog .imgs .img-right {
    margin-top: 0px;
    align-self: start;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .RightPartBlog {
    padding: 30px;
  }

  .RightPartBlog p {
    font-size: 18px;
  }

  .RightPartBlog .imgs {
    margin: 40px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .RightPartBlog .imgs .img-left,
  .RightPartBlog .imgs .img-right {
    width: 80%;
  }
  .RightPartBlog .imgs .img-left {
    align-self: end;
  }
  .RightPartBlog .imgs .img-right {
    margin-top: 0px;
    align-self: start;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .RightPartBlog p {
    font-size: 18px;
  }

  .RightPartBlog .imgs {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .RightPartBlog .imgs .img-left,
  .RightPartBlog .imgs .img-right {
    width: 80%;
  }
  .RightPartBlog .imgs .img-left {
    align-self: end;
  }
  .RightPartBlog .imgs .img-right {
    margin-top: 0px;
    align-self: start;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .RightPartBlog {
    padding: 25px;
  }

  .RightPartBlog p {
    font-size: 18px;
  }

  .RightPartBlog .imgs {
    margin-top: 70px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .RightPartBlog .imgs .img-left,
  .RightPartBlog .imgs .img-right {
    width: 40%;
  }
  .RightPartBlog .imgs .img-right {
    margin-top: -50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .RightPartBlog {
    padding: 35px 160px 35px 35px;
  }

  .RightPartBlog p {
    font-size: 18px;
  }

  .RightPartBlog .imgs {
    margin-top: 100px;
    margin-bottom: 50px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .RightPartBlog .imgs .img-left,
  .RightPartBlog .imgs .img-right {
    width: 40%;
  }
  .RightPartBlog .imgs .img-right {
    margin-top: -50px;
  }
}
