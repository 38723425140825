.RightPartGateway {
  height: auto;
  padding: 35px 160px 35px 35px;
  cursor: default;
}

.RightPartGateway p {
  font-size: 17px;
  font-weight: 400;
  color: var(--black-color);
  position: relative;
}
.RightPartGateway .imgs {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.RightPartGateway .imgs .img-left,
.RightPartGateway .imgs .img-right {
  width: 40%;
}
.RightPartGateway .imgs .img-left {
  margin-top: 50px;
}
.RightPartGateway .imgs .img-right {
  margin-top: -50px;
}

.RightPartGateway .quote {
  border-left: 1px solid var(--red-color);
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 50px 100px;
  padding: 20px 0 20px 10px;
}
.RightPartGateway .quote svg {
  transform: rotate(180deg);
  color: var(--red-color);
  margin-top: -10px;
}
.RightPartGateway .quote p {
  font-family: "Anton", "Arial", sans-serif;
  font-size: 1.2rem;
  margin-left: 3px;
}

.RightPartGateway a {
  font-size: 2.2rem;
  font-weight: 500;
}
.RightPartGateway a svg {
  transform: translateX(0px);
  transition: all 0.4s ease-in-out;
}
.RightPartGateway a:hover svg {
  transform: translateX(10px);
  transition: all 0.4s ease-in-out;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .RightPartGateway {
    padding: 25px;
  }

  .RightPartGateway p {
    font-size: 17px;
  }

  .RightPartGateway .imgs {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .RightPartGateway .imgs .img-left,
  .RightPartGateway .imgs .img-right {
    width: 80%;
  }
  .RightPartGateway .imgs .img-left {
    align-self: end;
  }
  .RightPartGateway .imgs .img-right {
    margin-top: 0px;
    align-self: start;
  }

  .RightPartGateway .quote {
    margin: 40px 0px;
    padding: 20px 0 0 10px;
  }

  .RightPartGateway a {
    font-size: 1.8rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .RightPartGateway {
    padding: 30px;
  }

  .RightPartGateway p {
    font-size: 18px;
  }

  .RightPartGateway .imgs {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .RightPartGateway .imgs .img-left,
  .RightPartGateway .imgs .img-right {
    width: 80%;
  }
  .RightPartGateway .imgs .img-left {
    align-self: end;
  }
  .RightPartGateway .imgs .img-right {
    margin-top: 0px;
    align-self: start;
  }

  .RightPartGateway .quote {
    margin: 40px 0px;
    padding: 20px 0 0 10px;
  }

  .RightPartGateway a {
    font-size: 1.8rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .RightPartGateway p {
    font-size: 18px;
  }

  .RightPartGateway .imgs {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .RightPartGateway .imgs .img-left,
  .RightPartGateway .imgs .img-right {
    width: 80%;
  }
  .RightPartGateway .imgs .img-left {
    align-self: end;
  }
  .RightPartGateway .imgs .img-right {
    margin-top: 0px;
    align-self: start;
  }

  .RightPartGateway .quote {
    margin: 40px 0px;
    padding: 20px 0 0 10px;
  }

  .RightPartGateway a {
    font-size: 1.8rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .RightPartGateway {
    padding: 25px;
  }

  .RightPartGateway p {
    font-size: 18px;
  }

  .RightPartGateway .imgs {
    margin-top: 70px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .RightPartGateway .imgs .img-left,
  .RightPartGateway .imgs .img-right {
    width: 40%;
  }
  .RightPartGateway .imgs .img-right {
    margin-top: -50px;
  }

  .RightPartGateway .quote {
    margin: 40px 0px;
    padding: 20px 0 0 10px;
  }

  .RightPartGateway a {
    font-size: 1.8rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .RightPartGateway {
    padding: 35px 160px 35px 35px;
  }

  .RightPartGateway p {
    font-size: 17px;
  }

  .RightPartGateway .imgs {
    margin-top: 80px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .RightPartGateway .imgs .img-left,
  .RightPartGateway .imgs .img-right {
    width: 40%;
  }
  .RightPartGateway .imgs .img-right {
    margin-top: -50px;
  }

  .RightPartGateway .quote {
    margin: 50px 100px;
    padding: 20px 0 20px 10px;
  }

  .RightPartGateway a {
    font-size: 2.2rem;
  }
}
