.Header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
}

/* Header */
.Header .navbar {
  padding: 0;
  padding-top: 10px;
  background-color: transparent;
}
.Header .navbar .container {
  width: 100%;
  max-width: 100%;
  padding: 0 50px;
}
.Header .navbar .container .navbar-collapse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Header .navbar-collapse .navbar-text {
  text-align: center;
  cursor: default;
}
.Header .navbar-collapse .navbar-text .hamburger-react {
  margin: 0 auto !important;
}
.Header .navbar-collapse .navbar-brand,
.Header .navbar-collapse .navbar-text {
  z-index: 1000;
  margin: 0 !important;
  display: block;
}
.Header .navbar-collapse .navbar-brand .hamburger-react {
  margin: 0 auto !important;
  display: block;
}
.Header .navbar-collapse .navbar-brand img {
  width: 7vw;
  margin-bottom: 5px;
}
.Header .navbar-collapse .navbar-brand svg {
  color: var(--grey-color);
  cursor: pointer;
}
.Header .navbar-collapse .navbar-text .title h6 {
  font-family: "Anton", "Arial", sans-serif;
  color: var(--red-color);
  font-size: 0.95vw;
  line-height: 0.1vw;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-weight: 400;
  transition: all 0.4s ease-in-out;
}
.Header .navbar-collapse .navbar-text .title p {
  font-family: "TheinhardtReg", "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.6vw;
  text-transform: uppercase;
  color: var(--black-color);
  display: block;
  letter-spacing: 0.2em;
  margin: 0;
  transition: all 0.4s ease-in-out;
}
.Header .navbar-collapse .navbar-text .title:hover h6 {
  letter-spacing: 0.2em;
  transition: all 0.4s ease-in-out;
}
.Header .navbar-collapse .navbar-text .title:hover p {
  letter-spacing: 0.1em;
  transition: all 0.4s ease-in-out;
}
.Header .navbar-collapse .navbar-text:nth-child(3) {
  padding: 0;
}
.Header .navbar-collapse .navbar-text a {
  font-family: "TheinhardtReg", "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9vw;
  letter-spacing: 0.25em;
  color: #000000;
  text-decoration: none;
}

/* Overlay menu */
.modal-backdrop {
  z-index: 98 !important;
}
.overlay-menu {
  z-index: 99 !important;
  padding: 0 !important;
}
.overlay-menu .modal-dialog {
  cursor: default;
  background-color: var(--white-color);
  margin: 0 !important;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}
.overlay-menu .modal-dialog .modal-content {
  border-radius: 0;
  max-height: 100%;
  height: 100%;
}
.overlay-menu .modal-dialog .modal-content .modal-header {
  border: none !important;
}
.overlay-menu .modal-dialog .modal-content .modal-header {
  margin: 0 !important;
}
.overlay-menu .modal-dialog .modal-content .modal-body {
  overflow: hidden;
}
.overlay-menu .modal-dialog .modal-content .modal-body ul {
  padding: 0;
  height: 100%;
  margin: 0 !important;
  padding: 80px 20px 20px 20px;
}
.overlay-menu .modal-dialog .modal-content .modal-body ul li {
  text-align: center;
  font-family: "Anton", "Arial", sans-serif;
  font-size: 2.5rem;
  color: var(--black-color);
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
}
.overlay-menu .modal-dialog .modal-content .modal-body ul li:hover {
  color: var(--brouwn-color);
  transition: all 0.4s ease-in-out;
}
.overlay-menu .modal-dialog .modal-content .modal-body ul a.active li {
  color: var(--brouwn-color);
}
.overlay-menu .modal-dialog .modal-content .modal-footer {
  border: none;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5%;
}
.overlay-menu .modal-dialog .modal-content .modal-footer p {
  color: var(--grey-color);
  font-size: 0.9rem;
  font-weight: 500;
}
.overlay-menu .modal-dialog .modal-content .modal-footer a {
  display: none;
}
.overlay-menu .modal-dialog .modal-content .modal-footer .social-media a {
  display: inline-block;
}
.overlay-menu .modal-dialog .modal-content .modal-footer .social-media a svg {
  color: var(--grey-color);
  margin-left: 10px;
  margin-right: 10px;
  transform: scale(1);
  transition: all 0.4s ease-in-out;
}
.overlay-menu
  .modal-dialog
  .modal-content
  .modal-footer
  .social-media
  a:hover
  svg {
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* Header */
  .Header .navbar .container .navbar-collapse {
    justify-content: center;
  }
  .Header .navbar-collapse .navbar-text .hamburger-react {
    display: block;
  }
  .Header .navbar-collapse .navbar-brand,
  .Header .navbar-collapse .navbar-text a {
    display: none;
  }
  .Header .navbar-collapse .navbar-text h6 {
    font-size: 2.8vw;
  }
  .Header .navbar-collapse .navbar-text p {
    font-size: 2vw;
    letter-spacing: 0.1em;
  }

  /* Overlay menu */
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    padding: 80px 20px 50px 20px;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a {
    display: block;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer {
    flex-direction: column;
    justify-content: space-center;
    padding-bottom: 40%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li {
    font-size: 1.8rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* Header */
  .Header .navbar .container .navbar-collapse {
    justify-content: center;
  }
  .Header .navbar-collapse .navbar-text .hamburger-react {
    display: block;
  }
  .Header .navbar-collapse .navbar-brand,
  .Header .navbar-collapse .navbar-text a {
    display: none;
  }
  .Header .navbar-collapse .navbar-text h6 {
    font-size: 2.5vw;
  }
  .Header .navbar-collapse .navbar-text p {
    font-size: 1.6vw;
    letter-spacing: 0.1em;
  }

  /* Overlay menu */
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    padding: 100px 20px 40px 20px;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a {
    display: block;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer {
    flex-direction: column;
    justify-content: space-center;
    padding-bottom: 15%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li {
    font-size: 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* Header */
  .Header .navbar .container .navbar-collapse {
    justify-content: space-between;
  }
  .Header .navbar-collapse .navbar-text .hamburger-react {
    display: none;
  }
  .Header .navbar-collapse .navbar-brand,
  .Header .navbar-collapse .navbar-text a {
    display: block;
  }
  .Header .navbar-collapse .navbar-text h6 {
    font-size: 0.95vw;
  }
  .Header .navbar-collapse .navbar-text p {
    font-size: 0.6vw;
    letter-spacing: 0.2em;
  }

  /* Overlay menu */
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    padding: 80px 20px 20px 20px;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li {
    font-size: 2.5rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.5%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* Header */
  .Header .navbar .container .navbar-collapse {
    justify-content: space-between;
  }
  .Header .navbar-collapse .navbar-text .hamburger-react {
    display: none;
  }
  .Header .navbar-collapse .navbar-brand,
  .Header .navbar-collapse .navbar-text a {
    display: block;
  }
  .Header .navbar-collapse .navbar-text h6 {
    font-size: 0.95vw;
  }
  .Header .navbar-collapse .navbar-text p {
    font-size: 0.6vw;
    letter-spacing: 0.2em;
  }

  /* Overlay menu */
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    padding: 80px 20px 20px 20px;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li {
    font-size: 2.5rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.5%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* Header */
  .Header .navbar .container .navbar-collapse {
    justify-content: space-between;
  }
  .Header .navbar-collapse .navbar-text .hamburger-react {
    display: none;
  }
  .Header .navbar-collapse .navbar-brand,
  .Header .navbar-collapse .navbar-text a {
    display: block;
  }
  .Header .navbar-collapse .navbar-text h6 {
    font-size: 0.95vw;
  }
  .Header .navbar-collapse .navbar-text p {
    font-size: 0.6vw;
    letter-spacing: 0.2em;
  }

  /* Overlay menu */
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    padding: 80px 20px 20px 20px;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li {
    font-size: 2.5rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.5%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a {
    display: none;
  }
}

/* Mac and iPad resolutions */
@media only screen and (min-width: 2000px) {
  /* Header */
  .Header .navbar .container {
    padding: 0 60px;
  }
  .Header .navbar .container .navbar-collapse {
    justify-content: space-between;
  }
  .Header .navbar-collapse .navbar-text .hamburger-react {
    display: none;
  }
  .Header .navbar-collapse .navbar-brand .hamburger-react {
    transform: scale(1.4) !important;
    margin-top: 10px !important;
  }
  .Header .navbar-collapse .navbar-brand,
  .Header .navbar-collapse .navbar-text a {
    display: block;
  }
  .Header .navbar-collapse .navbar-text h6 {
    font-size: 0.95vw;
  }
  .Header .navbar-collapse .navbar-text p {
    font-size: 0.6vw;
    letter-spacing: 0.2em;
  }

  /* Overlay menu */
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    padding: 120px 50px 50px 50px;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li {
    font-size: 3rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.8%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a {
    display: none;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer p {
    font-size: 1.2rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer .social-media a svg {
    margin-left: 15px;
    margin-right: 15px;
    transform: scale(1.4);
  }
  .overlay-menu
    .modal-dialog
    .modal-content
    .modal-footer
    .social-media
    a:hover
    svg {
    transform: scale(1.6);
  }
}
@media only screen and (min-width: 2500px) {
  /* Header */
  .Header .navbar .container {
    padding: 0 80px;
  }
  .Header .navbar .container .navbar-collapse {
    justify-content: space-between;
  }
  .Header .navbar-collapse .navbar-text .hamburger-react {
    display: none;
  }
  .Header .navbar-collapse .navbar-brand .hamburger-react {
    transform: scale(1.8) !important;
    margin-top: 15px !important;
  }
  .Header .navbar-collapse .navbar-brand,
  .Header .navbar-collapse .navbar-text a {
    display: block;
  }
  .Header .navbar-collapse .navbar-text h6 {
    font-size: 0.95vw;
    line-height: 0.4vw !important;
  }
  .Header .navbar-collapse .navbar-text p {
    font-size: 0.6vw;
    letter-spacing: 0.2em;
  }

  /* Overlay menu */
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    padding: 140px 70px 70px 70px;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li {
    font-size: 4.6rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a {
    display: none;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer p {
    font-size: 1.8rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer .social-media a svg {
    margin-left: 25px;
    margin-right: 25px;
    transform: scale(2);
  }
  .overlay-menu
    .modal-dialog
    .modal-content
    .modal-footer
    .social-media
    a:hover
    svg {
    transform: scale(2.2);
  }
}
@media only screen and (min-width: 2800px) {
  /* Header */
  .Header .navbar .container {
    padding: 0 110px;
  }
  .Header .navbar .container .navbar-collapse {
    justify-content: space-between;
  }
  .Header .navbar-collapse .navbar-text .hamburger-react {
    display: none;
  }
  .Header .navbar-collapse .navbar-brand .hamburger-react {
    transform: scale(2.8) !important;
    margin-top: 30px !important;
  }
  .Header .navbar-collapse .navbar-brand,
  .Header .navbar-collapse .navbar-text a {
    display: block;
  }
  .Header .navbar-collapse .navbar-text h6 {
    font-size: 0.95vw;
    line-height: 0.8vw !important;
  }
  .Header .navbar-collapse .navbar-text p {
    font-size: 0.6vw;
    letter-spacing: 0.2em;
  }

  /* Overlay menu */
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    padding: 280px 90px 90px 90px;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li {
    font-size: 5.8rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a {
    display: none;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer p {
    font-size: 2.5rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer .social-media a svg {
    margin-left: 45px;
    margin-right: 45px;
    transform: scale(2.6);
  }
  .overlay-menu
    .modal-dialog
    .modal-content
    .modal-footer
    .social-media
    a:hover
    svg {
    transform: scale(2.8);
  }
}
@media only screen and (min-width: 5000px) {
  /* Header */
  .Header .navbar .container {
    padding: 0 140px;
  }
  .Header .navbar .container .navbar-collapse {
    justify-content: space-between;
  }
  .Header .navbar-collapse .navbar-text .hamburger-react {
    display: none;
  }
  .Header .navbar-collapse .navbar-brand .hamburger-react {
    transform: scale(3.8) !important;
    margin-top: 45px !important;
  }
  .Header .navbar-collapse .navbar-brand,
  .Header .navbar-collapse .navbar-text a {
    display: block;
  }
  .Header .navbar-collapse .navbar-text h6 {
    font-size: 0.95vw;
    line-height: 1.2vw !important;
  }
  .Header .navbar-collapse .navbar-text p {
    font-size: 0.6vw;
    letter-spacing: 0.2em;
  }

  /* Overlay menu */
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    padding: 380px 180px 180px 180px;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li {
    font-size: 8.2rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a {
    display: none;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer p {
    font-size: 3.4rem;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer .social-media a svg {
    margin-left: 65px;
    margin-right: 65px;
    transform: scale(3.6);
  }
  .overlay-menu
    .modal-dialog
    .modal-content
    .modal-footer
    .social-media
    a:hover
    svg {
    transform: scale(3.8);
  }
}
