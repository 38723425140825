.ButtonSimple a,
.ButtonSimple button,
.ButtonSimple button,
.ButtonSimple button:focus,
.ButtonSimple button:active:focus {
  background-color: var(--light-grey-color);
  padding: 10px 35px;
  text-align: center;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  font-family: "Barlow", "Arial" sans-serif;
  text-transform: uppercase;
  position: relative;
  border: 1.5px solid var(--light-grey-color);
  border-radius: 0;
  box-shadow: none;
  transition: all 0.4s ease-in-out;
}
.ButtonSimple:hover a,
.ButtonSimple:hover button {
  border: 1.5px solid var(--black-color);
  background-color: var(--light-grey-color);
  color: var(--black-color);
  transition: all 0.4s ease-in-out;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .ButtonSimple a {
    padding: 10px 35px;
    font-size: 15px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .ButtonSimple a {
    padding: 10px 35px;
    font-size: 16px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ButtonSimple a {
    padding: 10px 35px;
    font-size: 16px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ButtonSimple a {
    padding: 10px 35px;
    font-size: 16px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .ButtonSimple a {
    padding: 10px 35px;
    font-size: 14px;
  }
}
