.LeftPartGraduate {
  height: auto;
  padding: 35px;
  cursor: default;
}

.LeftPartGraduate .sdgs p {
  color: var(--grey-color);
  margin: 20px 0;
}
.LeftPartGraduate .sdgs .sdg-imgs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: stretch;
  justify-content: flex-start;
  gap: 10px;
}
.LeftPartGraduate .sdgs .sdg-imgs img {
  width: 100px;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .LeftPartGraduate {
    padding: 25px;
  }

  /* Second Part */
  .sdgs hr {
    width: 80%;
  }
  .LeftPartGraduate .sdgs .sdg-imgs {
    gap: 6px;
  }
  .LeftPartGraduate .sdgs .sdg-imgs img {
    width: 90px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .LeftPartGraduate {
    padding: 30px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .LeftPartGraduate {
    padding: 25px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .LeftPartGraduate {
    padding: 35px;
  }

  /* Second Part */
  .sdgs hr {
    width: 100%;
  }
  .LeftPartGraduate .sdgs .sdg-imgs {
    gap: 10px;
  }
  .LeftPartGraduate .sdgs .sdg-imgs img {
    width: 100px;
  }
}
