/* Global */
.ContactForm,
.RegisterForm,
.SponsorForm,
.SubscribeForm {
  width: 100%;
  height: auto;
}
.SponsorForm form .row {
  margin-bottom: 30px !important;
}
.SponsorForm form .row div textarea {
  margin-top: 60px !important;
  margin-bottom: 30px !important;
}

/* Text input and textarea */
.ContactForm .form-control,
.RegisterForm .form-control,
.SponsorForm .form-control,
.SubscribeForm .form-control {
  border: none;
  border-bottom: 1px solid var(--input-grey-color);
  border-radius: 0;
  padding: 0 0 0.5rem 0.5rem;
  transition: all 0.4s ease-in-out;
}
.ContactForm .form-control:focus,
.RegisterForm .form-control:focus,
.SponsorForm .form-control:focus,
.SubscribeForm .form-control:focus {
  box-shadow: none !important;
  border-bottom: 1px solid var(--brouwn-color);
  transition: all 0.4s ease-in-out;
}
.ContactForm .form-control::-webkit-input-placeholder,
.RegisterForm .form-control::-webkit-input-placeholder,
.SponsorForm .form-control::-webkit-input-placeholder,
.SubscribeForm .form-control::-webkit-input-placeholder {
  color: var(--input-grey-color);
  font-style: italic;
  text-transform: capitalize;
}

/* Select option */
.ContactForm .select-input,
.RegisterForm .select-input,
.SponsorForm .select-input,
.SubscribeForm .select-input {
  font-style: italic;
  text-transform: capitalize;
}
.ContactForm .select-input .select__control,
.RegisterForm .select-input .select__control,
.SponsorForm .select-input .select__control,
.SubscribeForm .select-input .select__control {
  border: none;
  border-bottom: 1px solid var(--input-grey-color);
  border-radius: 0;
  padding: 0;
  color: var(--input-grey-color);
  transition: all 0.4s ease-in-out;
  height: 40px !important;
  min-height: 20px !important;
  max-height: 33px !important;
}
.ContactForm .select-input .select__control.select__control--is-focused,
.RegisterForm .select-input .select__control.select__control--is-focused,
.SponsorForm .select-input .select__control.select__control--is-focused,
.ContactForm
  .select-input
  .select__control.select__control--is-focused.select__control--menu-is-open,
.RegisterForm
  .select-input
  .select__control.select__control--is-focused.select__control--menu-is-open,
.SponsorForm
  .select-input
  .select__control.select__control--is-focused.select__control--menu-is-open,
.SubscribeForm
  .select-input
  .select__control.select__control--is-focused.select__control--menu-is-open {
  box-shadow: none;
}
.ContactForm .select-input .select__control .select__value-container,
.RegisterForm .select-input .select__control .select__value-container,
.SponsorForm .select-input .select__control .select__value-container,
.SubscribeForm .select-input .select__control .select__value-container {
  padding: 0;
  padding: 0 0 0.5rem 0.5rem;
}
.ContactForm
  .select-input
  .select__control
  .select__indicators
  .select__indicator-separator,
.RegisterForm
  .select-input
  .select__control
  .select__indicators
  .select__indicator-separator,
.SponsorForm
  .select-input
  .select__control
  .select__indicators
  .select__indicator-separator,
.SubscribeForm
  .select-input
  .select__control
  .select__indicators
  .select__indicator-separator {
  display: none;
}
.ContactForm
  .select-input
  .select__control
  .select__indicators
  .select__clear-indicator,
.RegisterForm
  .select-input
  .select__control
  .select__indicators
  .select__clear-indicator,
.SponsorForm
  .select-input
  .select__control
  .select__indicators
  .select__clear-indicator,
.SubscribeForm
  .select-input
  .select__control
  .select__indicators
  .select__clear-indicator {
  margin-right: -10px;
}
/* => Menu select option */
.ContactForm .select-input .select__menu,
.RegisterForm .select-input .select__menu,
.SponsorForm .select-input .select__menu,
.SubscribeForm .select-input .select__menu {
  border-color: #c4c4c4;
  border-radius: 0;
  padding: 0;
  color: var(--input-grey-color);
}
.ContactForm .select-input .select__menu .select__menu-list,
.RegisterForm .select-input .select__menu .select__menu-list,
.SponsorForm .select-input .select__menu .select__menu-list,
.SubscribeForm .select-input .select__menu .select__menu-list {
  padding: 0;
}
.ContactForm .select-input .select__menu .select__menu-list .select__option,
.RegisterForm .select-input .select__menu .select__menu-list .select__option,
.SponsorForm .select-input .select__menu .select__menu-list .select__option,
.SubscribeForm .select-input .select__menu .select__menu-list .select__option {
  cursor: pointer;
  border-bottom: 1px solid #c4c4c4;
  position: relative;
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:last-child,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:last-child,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:last-child,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:last-child {
  border-bottom: none;
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:hover:before,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:hover:before,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:hover:before,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:hover:before {
  content: "";
  position: absolute;
  left: 0;
  height: 80%;
  width: 4px;
  margin-top: -3px;
  background-color: var(--brouwn-color);
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-focused,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-focused,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-focused,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-focused {
  background-color: transparent;
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected {
  background-color: transparent;
  color: var(--brouwn-color);
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected:before,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected:before,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected:before,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected:before {
  content: "";
  position: absolute;
  left: 0;
  height: 80%;
  width: 4px;
  margin-top: -3px;
  background-color: var(--brouwn-color);
}
