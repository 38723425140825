.Intro {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--black-color);
  overflow: hidden;
  user-select: none;
}
.Intro .bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.Intro .bg-intro {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.Intro .content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45vw;
  height: 55vh;
  cursor: default;
}
.Intro .content .intro-card {
  position: relative;
  width: 34em;
  height: 20em;
  max-width: 34em;
  max-height: 20em;
}
.Intro .content .intro-card div {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--light-grey-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.Intro .content .intro-card .logo {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: -30px;
}
.Intro .content .intro-card .logo p {
  position: relative;
  font-family: "Playfair Display", "Arial", serif !important;
  font-size: 91px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: center;
  margin: 0;
  padding: 0;
  color: var(--black-color);
  text-transform: uppercase;
  height: auto;
  margin-bottom: -20px !important;
}
.Intro .content .intro-card .logo span {
  position: relative;
  font-family: "Playfair Display" !important;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.53em;
  text-align: center;
  margin: 0;
  padding: 0;
  color: var(--black-color);
  text-transform: capitalize;
}
.Intro .content .intro-card ul {
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0;
  padding-bottom: 30px;
}
.Intro .content .intro-card ul li {
  display: inline-block;
  flex-direction: row;
  font-size: 1.1rem;
  font-weight: 500;
}
.Intro .content .intro-card ul li:not(:nth-child(1)) {
  margin-left: 1.5rem;
}
.Intro .content .intro-card .img-border {
  position: relative;
  width: 100%;
  height: 100%;
}
.Intro .content p {
  font-family: "Anton", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 100;
  color: var(--white-color);
  text-align: center;
  margin-top: 25px;
  letter-spacing: 0.5px;
  width: 35em;
  max-width: 35em;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Intro .content {
    width: 85vw;
    height: 40vh;
  }
  .Intro .content .intro-card .logo {
    margin-top: -30px;
  }
  .Intro .content .intro-card .logo p {
    font-size: 71px;
    margin-bottom: -20px !important;
  }
  .Intro .content .intro-card .logo span {
    font-size: 12px;
  }
  .Intro .content .intro-card ul {
    padding-bottom: 30px;
  }
  .Intro .content .intro-card ul li:not(:nth-child(1)) {
    margin-left: 0.8rem;
  }
  .Intro .content .intro-card ul li {
    font-size: 0.8rem;
  }
  .Intro .content p {
    font-size: 0.8em;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Intro .content {
    width: 65vw;
    height: 55vh;
  }
  .Intro .content .intro-card .logo {
    margin-top: -30px;
  }
  .Intro .content .intro-card .logo p {
    font-size: 86px;
    margin-bottom: -10px !important;
  }
  .Intro .content .intro-card .logo span {
    font-size: 14px;
  }
  .Intro .content .intro-card ul {
    padding-bottom: 30px;
  }
  .Intro .content .intro-card ul li:not(:nth-child(1)) {
    margin-left: 0.8rem;
  }
  .Intro .content .intro-card ul li {
    font-size: 0.8rem;
  }
  .Intro .content p {
    font-size: 0.8em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Intro .content {
    width: 60vw;
    height: 60vh;
  }
  .Intro .content .intro-card .logo {
    margin-top: -30px;
  }
  .Intro .content .intro-card .logo p {
    font-size: 91px;
    margin-bottom: -10px !important;
  }
  .Intro .content .intro-card .logo span {
    font-size: 14px;
  }
  .Intro .content .intro-card ul {
    padding-bottom: 30px;
  }
  .Intro .content .intro-card ul li:not(:nth-child(1)) {
    margin-left: 1rem;
  }
  .Intro .content .intro-card ul li {
    font-size: 1.2rem;
  }
  .Intro .content p {
    font-size: 1em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Intro .content {
    width: 50vw;
    height: 50vh;
  }
  .Intro .content .intro-card .logo {
    margin-top: -30px;
  }
  .Intro .content .intro-card .logo p {
    font-size: 91px;
    margin-bottom: -10px !important;
  }
  .Intro .content .intro-card .logo span {
    font-size: 14px;
  }
  .Intro .content .intro-card ul {
    padding-bottom: 30px;
  }
  .Intro .content .intro-card ul li:not(:nth-child(1)) {
    margin-left: 1rem;
  }
  .Intro .content .intro-card ul li {
    font-size: 1.2rem;
  }
  .Intro .content p {
    font-size: 1em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .Intro .content {
    width: 45vw;
    height: 55vh;
  }
  .Intro .content .intro-card .logo {
    margin-top: -30px;
  }
  .Intro .content .intro-card .logo p {
    font-size: 91px;
    margin-bottom: -20px !important;
  }
  .Intro .content .intro-card .logo span {
    font-size: 14px;
  }
  .Intro .content .intro-card ul {
    padding-bottom: 30px;
  }
  .Intro .content .intro-card ul li:not(:nth-child(1)) {
    margin-left: 1.5rem;
  }
  .Intro .content .intro-card ul li {
    font-size: 1.1rem;
  }
  .Intro .content p {
    font-size: 1em;
  }
}
