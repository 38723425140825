.BottomPartGraduate {
  width: 100%;
  height: auto;
  cursor: default;
  margin-top: 5vh;
}

/* First part */
.BottomPartGraduate .career-part {
  background-color: var(--black-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 18%;
  height: auto;
  text-align: center;
}
.BottomPartGraduate .career-part h3 {
  font-family: "Barlow", "Arial", sans-serif;
  color: var(--white-color);
  font-weight: 800;
  font-size: 4.5rem;
  margin-bottom: 25px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}
.BottomPartGraduate .career-part p {
  color: var(--white-color);
  font-size: 1.1rem;
  text-align: center;
}
.BottomPartGraduate .career-part .row {
  padding: 0 50px;
}
.BottomPartGraduate .career-part .career-list {
  margin-top: 20px;
  width: 50%;
}
.BottomPartGraduate .career-part .career-list ul {
  margin: 0;
  padding: 0;
  padding: 0 20px;
  border-left: 1px solid var(--red-color);
  width: 70%;
}
.BottomPartGraduate .career-part .career-list ul li {
  font-size: 1.3rem;
  font-family: "Anton", "Arial", sans-serif;
  color: var(--white-color);
  margin-top: 5px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  width: 100%;
}
.BottomPartGraduate .career-part .career-list ul li:first-child {
  margin-top: 0;
}
.BottomPartGraduate .career-part .ButtonSimple {
  margin-top: 60px;
}
.BottomPartGraduate .career-part .ButtonSimple a {
  background-color: var(--brouwn-color);
  text-transform: capitalize;
  border-color: var(--brouwn-color);
  padding: 8px 80px;
}
.BottomPartGraduate .career-part .ButtonSimple:hover a {
  border-color: var(--black-color);
}

/* Second Part */
.BottomPartGraduate .cohort-part {
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 18%;
  height: auto;
}
.BottomPartGraduate .cohort-part h3 {
  font-family: "Barlow", "Arial", sans-serif;
  color: var(--black-color);
  font-weight: 800;
  font-size: 4.5rem;
  margin-bottom: 50px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}
.BottomPartGraduate .cohort-part .list-cohort {
  padding: 0;
}
.BottomPartGraduate .cohort-part .list-cohort .item-list {
  margin-bottom: 20px;
  height: auto;
  cursor: default;
  text-transform: capitalize;
}
.BottomPartGraduate .cohort-part .list-cohort .item-list div {
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--divider-grey-color);
}
.BottomPartGraduate .cohort-part .list-cohort .item-list div:first-child {
  margin-right: 80px;
}
.BottomPartGraduate .cohort-part .list-cohort .item-list div:last-child {
  margin-left: 80px;
  display: block;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* First part */
  .BottomPartGraduate .career-part,
  .BottomPartGraduate .cohort-part {
    padding: 15% 4%;
  }
  .BottomPartGraduate .career-part h3,
  .BottomPartGraduate .cohort-part h3 {
    font-size: 1.5rem;
  }
  .BottomPartGraduate .career-part p {
    font-size: 1rem;
    display: inline-flex;
  }
  .BottomPartGraduate .career-part .row {
    padding: 0 20px;
  }
  .BottomPartGraduate .career-part .career-list {
    width: 50%;
  }
  .BottomPartGraduate .career-part .career-list ul {
    width: 100%;
  }
  .BottomPartGraduate .career-part .career-list ul li {
    font-size: 1rem;
  }

  /* Second part */
  .BottomPartGraduate .cohort-part .list-cohort .item-list div {
    font-size: 20px;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:first-child {
    margin-right: 0;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:last-child {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* First part */
  .BottomPartGraduate .career-part,
  .BottomPartGraduate .cohort-part {
    padding: 15% 4%;
  }
  .BottomPartGraduate .career-part h3,
  .BottomPartGraduate .cohort-part h3 {
    font-size: 2.4rem;
  }
  .BottomPartGraduate .career-part p {
    font-size: 1.1rem;
  }
  .BottomPartGraduate .career-part .row {
    padding: 0 50px;
  }
  .BottomPartGraduate .career-part .career-list {
    width: 50%;
  }
  .BottomPartGraduate .career-part .career-list ul {
    width: 100%;
  }
  .BottomPartGraduate .career-part .career-list ul li {
    font-size: 1.2rem;
  }

  /* Second part */
  .BottomPartGraduate .cohort-part .list-cohort .item-list div {
    font-size: 20px;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:first-child {
    margin-right: 0;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:last-child {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* First part */
  .BottomPartGraduate .career-part,
  .BottomPartGraduate .cohort-part {
    padding: 10% 4%;
  }
  .BottomPartGraduate .career-part h3,
  .BottomPartGraduate .cohort-part h3 {
    font-size: 3rem;
  }
  .BottomPartGraduate .career-part p {
    font-size: 1.2rem;
  }
  .BottomPartGraduate .career-part .row {
    padding: 0 50px;
  }
  .BottomPartGraduate .career-part .career-list {
    width: 50%;
  }
  .BottomPartGraduate .career-part .career-list ul {
    width: 100%;
  }
  .BottomPartGraduate .career-part .career-list ul li {
    font-size: 1.3rem;
  }

  /* Second part */
  .BottomPartGraduate .cohort-part .list-cohort .item-list div {
    font-size: 24px;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:first-child {
    margin-right: 80px;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:last-child {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* First part */
  .BottomPartGraduate .career-part,
  .BottomPartGraduate .cohort-part {
    padding: 8% 15%;
  }
  .BottomPartGraduate .career-part h3,
  .BottomPartGraduate .cohort-part h3 {
    font-size: 3.5rem;
  }
  .BottomPartGraduate .career-part p {
    font-size: 1.2rem;
  }
  .BottomPartGraduate .career-part .row {
    padding: 0 50px;
  }
  .BottomPartGraduate .career-part .career-list {
    width: 50%;
  }
  .BottomPartGraduate .career-part .career-list ul {
    width: 100%;
  }
  .BottomPartGraduate .career-part .career-list ul li {
    font-size: 1.3rem;
  }

  /* Second part */
  .BottomPartGraduate .cohort-part .list-cohort .item-list div {
    font-size: 24px;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:first-child {
    margin-right: 80px;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:last-child {
    display: block;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* First part */
  .BottomPartGraduate .career-part,
  .BottomPartGraduate .cohort-part {
    padding: 5% 18%;
  }
  .BottomPartGraduate .career-part h3,
  .BottomPartGraduate .cohort-part h3 {
    font-size: 4.5rem;
  }
  .BottomPartGraduate .career-part p {
    font-size: 1.1rem;
  }
  .BottomPartGraduate .career-part .row {
    padding: 0 50px;
  }
  .BottomPartGraduate .career-part .career-list {
    width: 50%;
  }
  .BottomPartGraduate .career-part .career-list ul {
    width: 70%;
  }
  .BottomPartGraduate .career-part .career-list ul li {
    font-size: 1.3rem;
  }

  /* Second part */
  .BottomPartGraduate .cohort-part .list-cohort .item-list div {
    font-size: 24px;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:first-child {
    margin-right: 80px;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div:last-child {
    display: block;
  }
}
