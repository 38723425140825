.Partners {
  width: 100%;
  height: auto;
  margin-bottom: 15vh;
}

.Partners .container-list {
  width: 50%;
  padding: 0 15px;
  margin: 0 auto;
  cursor: default;
}
.Partners .container-list p {
  font-size: 1em;
  margin-bottom: 80px;
}
.Partners .container-list ul {
  margin: 0;
  padding: 0;
}
.Partners .container-list ul li {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--divider-grey-color);
  height: 90px;
}
.Partners .container-list ul li .logo {
  width: 90px;
  height: 90px;
  position: relative;
  margin: 0 auto;
}
.Partners .container-list ul li:first-child .logo {
  width: 70px;
  height: 70px;
  position: relative;
  margin: 0 auto;
}
.Partners .container-list ul li .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Partners .container-list ul li .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Partners .container-list ul li h6 {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 1.1em;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}
.Partners .container-list ul li:hover h6 {
  padding-left: 20px;
  transition: all 0.4s ease-in-out;
}
.Partners .container-list .ButtonSimple {
  text-align: center;
  margin-top: 90px;
}
.Partners .container-list .ButtonSimple button,
.Partners .container-list .ButtonSimple button:focus,
.Partners .container-list .ButtonSimple button:active:focus {
  background-color: var(--brouwn-color);
  text-transform: capitalize;
  border-color: var(--brouwn-color);
}
.Partners .container-list .ButtonSimple:hover button {
  border-color: var(--white-color);
}

/* Modal */
.modal.fade .modal-dialog .modal-content {
  border: none;
  border-radius: 0;
}
.modal.fade .modal-dialog .modal-content .modal-header {
  border-bottom: none;
}
.modal.fade .modal-dialog .modal-content .modal-header button:focus {
  box-shadow: none;
}
.modal.fade .modal-dialog .SponsorForm {
  margin-bottom: 30px;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Partners {
    margin-bottom: 12vh;
  }
  .Partners .container-list {
    width: 100%;
    padding: 0 15px;
  }
  .Partners .container-list ul li h6 {
    font-size: 1em;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Partners {
    margin-bottom: 12vh;
  }
  .Partners .container-list {
    width: 100%;
    padding: 0 20px;
  }
  .Partners .container-list ul li h6 {
    font-size: 1.1em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Partners {
    margin-bottom: 13vh;
  }
  .Partners .container-list {
    width: 100%;
    padding: 0 30px;
  }
  .Partners .container-list ul li h6 {
    font-size: 1.2em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Partners {
    margin-bottom: 14vh;
  }
  .Partners .container-list {
    width: 80%;
    padding: 0 15px;
  }
  .Partners .container-list ul li h6 {
    font-size: 1.1em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .Partners {
    margin-bottom: 15vh;
  }
  .Partners .container-list {
    width: 50%;
    padding: 0 15px;
  }
  .Partners .container-list ul li h6 {
    font-size: 1.1em;
  }
}
