.BottomPartGateway {
  width: 100%;
  height: auto;
  cursor: default;
  margin-top: 20vh;
}

/* First part */
.BottomPartGateway .donation {
  background-color: var(--black-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 18%;
  height: auto;
}
.BottomPartGateway .donation h3 {
  font-family: "Barlow", "Arial", sans-serif;
  color: var(--white-color);
  font-weight: bold;
  font-size: 4.5rem;
  margin-bottom: 25px;
  text-transform: uppercase;
  width: 70%;
}
.BottomPartGateway .donation p {
  color: var(--white-color);
  font-size: 1.1rem;
}
.BottomPartGateway .donation .ButtonSimple a {
  background-color: var(--brouwn-color);
  text-transform: capitalize;
  border-color: var(--brouwn-color);
}
.BottomPartGateway .donation .ButtonSimple:hover a {
  border-color: var(--black-color);
}

/* Second Part */
.BottomPartGateway .next-post {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.BottomPartGateway .next-post p {
  font-weight: 600;
  margin: 0;
  text-align: center;
}
.BottomPartGateway .next-post p:first-child {
  color: var(--grey-color);
  font-size: 0.8rem;
}
.BottomPartGateway .next-post p:last-child {
  color: var(--black-color);
  font-size: 1.2rem;
}
.BottomPartGateway .next-post .post {
  height: 50vh;
  width: 50% !important;
  position: relative;
  margin: 60px 0;
}
.BottomPartGateway .next-post .post img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 5;
}
.BottomPartGateway .next-post .post h1 {
  position: absolute;
  color: #ff3e32;
  font-size: 6vh;
  font-family: "Anton", "Arial", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  bottom: -4vh;
  z-index: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  z-index: 10;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* First part */
  .BottomPartGateway .donation {
    padding: 15% 8%;
  }
  .BottomPartGateway .donation h3 {
    font-size: 3rem;
    width: 100%;
  }
  .BottomPartGateway .donation p {
    font-size: 1.1rem;
  }

  /* Second part */
  .BottomPartGateway .next-post .post {
    height: 20vh;
    width: 100% !important;
    position: relative;
    margin: 60px 0;
  }
  .BottomPartGateway .next-post .post h1 {
    font-size: 3.5vh;
    bottom: -3vh;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* First part */
  .BottomPartGateway .donation {
    padding: 15% 8%;
  }
  .BottomPartGateway .donation h3 {
    font-size: 3rem;
    width: 100%;
  }
  .BottomPartGateway .donation p {
    font-size: 1.1rem;
  }

  /* Second part */
  .BottomPartGateway .next-post .post {
    height: 30vh;
    width: 80% !important;
    position: relative;
    margin: 60px 0;
  }
  .BottomPartGateway .next-post .post h1 {
    font-size: 4vh;
    bottom: -2.5vh;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* First part */
  .BottomPartGateway .donation {
    padding: 10% 15%;
  }
  .BottomPartGateway .donation h3 {
    font-size: 3.5rem;
    width: 100%;
  }
  .BottomPartGateway .donation p {
    font-size: 1.2rem;
  }

  /* Second part */
  .BottomPartGateway .next-post .post {
    height: 35vh;
    width: 70% !important;
    position: relative;
    margin: 60px 0;
  }
  .BottomPartGateway .next-post .post h1 {
    font-size: 4.5vh;
    bottom: -3vh;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* First part */
  .BottomPartGateway .donation {
    padding: 8% 15%;
  }
  .BottomPartGateway .donation h3 {
    font-size: 3.5rem;
    width: 100%;
  }
  .BottomPartGateway .donation p {
    font-size: 1.2rem;
  }

  /* Second part */
  .BottomPartGateway .next-post .post {
    height: 35vh;
    width: 60% !important;
    position: relative;
    margin: 60px 0;
  }
  .BottomPartGateway .next-post .post h1 {
    font-size: 5vh;
    bottom: -3.5vh;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* First part */
  .BottomPartGateway .donation {
    padding: 5% 18%;
  }
  .BottomPartGateway .donation h3 {
    font-size: 4.5rem;
    width: 70%;
  }
  .BottomPartGateway .donation p {
    font-size: 1.1rem;
  }

  /* Second part */
  .BottomPartGateway .next-post .post {
    height: 50vh;
    width: 50% !important;
    position: relative;
    margin: 60px 0;
  }
  .BottomPartGateway .next-post .post h1 {
    font-size: 6vh;
    bottom: -4vh;
  }
}
