.BottomPartBlog {
  width: 100%;
  height: auto;
  cursor: default;
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--black-color);
  padding: 5% 22%;
}

/* First part */
.BottomPartBlog h3 {
  font-family: "Barlow", "Arial", sans-serif;
  color: var(--white-color);
  font-weight: 800;
  font-size: 4.2em;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.BottomPartBlog p {
  color: var(--white-color);
  font-size: 1.1em;
  width: 80%;
}

/* Second Part */
.BottomPartBlog .SubscribeForm {
  margin-top: 50px;
}
.BottomPartBlog .SubscribeForm .form-control {
  background-color: transparent;
  color: var(--white-color);
}
.BottomPartBlog .SubscribeForm .ButtonSubmit {
  justify-content: flex-start !important;
  margin-top: 70px;
}
.BottomPartBlog .SubscribeForm .ButtonSubmit button:hover {
  border: 1px solid var(--black-color);
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .BottomPartBlog {
    padding: 15% 8%;
    margin-top: 5vh;
  }

  /* First part */
  .BottomPartBlog h3 {
    font-size: 1.6em;
    margin-bottom: 15px;
  }
  .BottomPartBlog p {
    font-size: 0.9em;
    width: 100%;
  }

  /* Second part */
  .BottomPartBlog .SubscribeForm {
    margin-top: 10px;
  }
  .BottomPartBlog .SubscribeForm .ButtonSubmit {
    margin-top: 30px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .BottomPartBlog {
    padding: 8% 20%;
    margin-top: 8vh;
  }

  /* First part */
  .BottomPartBlog h3 {
    font-size: 2.4em;
    margin-bottom: 15px;
  }
  .BottomPartBlog p {
    font-size: 1.1em;
    width: 100%;
  }

  /* Second part */
  .BottomPartBlog .SubscribeForm {
    margin-top: 30px;
  }
  .BottomPartBlog .SubscribeForm .ButtonSubmit {
    margin-top: 50px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .BottomPartBlog {
    padding: 8% 24%;
    margin-top: 10vh;
  }

  /* First part */
  .BottomPartBlog h3 {
    font-size: 2.6em;
    margin-bottom: 15px;
  }
  .BottomPartBlog p {
    font-size: 1.1em;
    width: 100%;
  }

  /* Second part */
  .BottomPartBlog .SubscribeForm {
    margin-top: 30px;
  }
  .BottomPartBlog .SubscribeForm .ButtonSubmit {
    margin-top: 50px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .BottomPartBlog {
    padding: 8% 18%;
    margin-top: 15vh;
  }

  /* First part */
  .BottomPartBlog h3 {
    font-size: 4.2em;
    margin-bottom: 25px;
  }
  .BottomPartBlog p {
    font-size: 1.2em;
    width: 70%;
  }

  /* Second Part */
  .BottomPartBlog .SubscribeForm {
    margin-top: 50px;
  }
  .BottomPartBlog .SubscribeForm .ButtonSubmit {
    margin-top: 70px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .BottomPartBlog {
    padding: 5% 22%;
    margin-top: 15vh;
  }

  /* First part */
  .BottomPartBlog h3 {
    font-size: 4.2em;
    margin-bottom: 25px;
  }
  .BottomPartBlog p {
    font-size: 1.1em;
    width: 80%;
  }

  /* Second Part */
  .BottomPartBlog .SubscribeForm {
    margin-top: 50px;
  }
  .BottomPartBlog .SubscribeForm .ButtonSubmit {
    margin-top: 70px;
  }
}
