.MentorsTable {
  width: 100%;
  height: auto;
  margin-bottom: 20vh;
}
.MentorsTable p {
  font-size: 1em;
  margin-bottom: 80px;
}

/* Cards list */
.MentorsTable .list-mentors {
  padding: 0;
}
.MentorsTable .list-mentors .item-mentor {
  margin-bottom: 65px;
  height: auto;
  border-bottom: 1px solid var(--divider-grey-color);
  padding-bottom: 15px;
  cursor: default;
}
.MentorsTable .list-mentors .item-mentor .part-1 img {
  width: 100px;
  height: 120px;
  position: relative;
  object-fit: cover;
}
.MentorsTable .list-mentors .item-mentor .part-2 h6,
.MentorsTable .list-mentors .item-mentor .part-2 p,
.MentorsTable .list-mentors .item-mentor .social {
  padding-left: 26px;
  text-transform: capitalize;
}
.MentorsTable .list-mentors .item-mentor .part-2 h6 {
  font-size: 1.4em;
  font-weight: 700;
  margin: 0;
  margin-top: 2px;
}
.MentorsTable .list-mentors .item-mentor .part-2 p {
  font-size: 0.8em;
  margin: 3px 0 16px;
  color: var(--grey-color);
}
.MentorsTable .list-mentors .item-mentor .social a svg {
  color: var(--grey-color);
  margin-right: 10px;
  transform: scale(1);
  transition: all 0.4s ease-in-out;
}
.MentorsTable .list-mentors .item-mentor .social a:hover svg {
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
}

.MentorsTable .list-mentors .item-mentor .part-3 p {
  margin: 0;
  color: var(--grey-color);
  font-size: 0.8em;
  padding-right: 20px;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .MentorsTable {
    margin-bottom: 10vh;
  }
  .MentorsTable p {
    font-size: 1em;
    margin-bottom: 60px;
  }

  .MentorsTable .list-mentors .item-mentor {
    margin-bottom: 50px;
  }
  .MentorsTable .list-mentors .item-mentor .part-1 img {
    width: 80px;
    height: 100px;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6 {
    font-size: 1.2em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .part-3 p {
    font-size: 0.6em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6,
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .social {
    padding-left: 48px;
  }
  .MentorsTable .list-mentors .pagination .next.pagination-item::after {
    transform: scale(4);
  }
  .MentorsTable .list-mentors .pagination .prev.pagination-item::before {
    transform: scale(4);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .MentorsTable {
    margin-bottom: 10vh;
  }
  .MentorsTable p {
    font-size: 1.1em;
    margin-bottom: 50px;
  }
  .MentorsTable .list-mentors .item-mentor {
    margin-bottom: 50px;
  }
  .MentorsTable .list-mentors .item-mentor .part-1 img {
    width: 100px;
    height: 120px;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6 {
    font-size: 1.5em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .part-3 p {
    font-size: 0.9em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6,
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .social {
    padding-left: 35px;
  }
  .MentorsTable .list-mentors .pagination .next.pagination-item::after {
    transform: scale(4);
  }
  .MentorsTable .list-mentors .pagination .prev.pagination-item::before {
    transform: scale(4);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .MentorsTable {
    margin-bottom: 10vh;
  }
  .MentorsTable p {
    font-size: 1.2em;
    margin-bottom: 50px;
  }
  .MentorsTable .list-mentors .item-mentor {
    margin-bottom: 50px;
  }
  .MentorsTable .list-mentors .item-mentor .part-1 img {
    width: 120px;
    height: 140px;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6 {
    font-size: 1.6em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .part-3 p {
    font-size: 0.95em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6,
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .social {
    padding-left: 20px;
  }
  .MentorsTable .list-mentors .pagination .next.pagination-item::after {
    transform: scale(4);
  }
  .MentorsTable .list-mentors .pagination .prev.pagination-item::before {
    transform: scale(4);
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .MentorsTable {
    margin-bottom: 10vh;
  }
  .MentorsTable p {
    font-size: 1.2em;
    margin-bottom: 50px;
  }
  .MentorsTable .list-mentors .item-mentor {
    margin-bottom: 50px;
  }
  .MentorsTable .list-mentors .item-mentor .part-1 img {
    width: 120px;
    height: 140px;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6 {
    font-size: 1.6em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .part-3 p {
    font-size: 0.95em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6,
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .social {
    padding-left: 20px;
  }
  .MentorsTable .list-mentors .pagination .next.pagination-item::after {
    transform: scale(4);
  }
  .MentorsTable .list-mentors .pagination .prev.pagination-item::before {
    transform: scale(4);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .MentorsTable {
    margin-bottom: 20vh;
  }
  .MentorsTable p {
    font-size: 1em;
    margin-bottom: 80px;
  }

  .MentorsTable .list-mentors .item-mentor {
    margin-bottom: 65px;
  }
  .MentorsTable .list-mentors .item-mentor .part-1 img {
    width: 100px;
    height: 120px;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6 {
    font-size: 1.4em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .part-3 p {
    font-size: 0.8em;
  }
  .MentorsTable .list-mentors .item-mentor .part-2 h6,
  .MentorsTable .list-mentors .item-mentor .part-2 p,
  .MentorsTable .list-mentors .item-mentor .social {
    padding-left: 26px;
  }
  .MentorsTable .list-mentors .pagination .next.pagination-item::after {
    transform: scale(5);
  }
  .MentorsTable .list-mentors .pagination .prev.pagination-item::before {
    transform: scale(5);
  }
}
