.Team {
  width: 100%;
  height: auto;
  margin-bottom: 15vh;
}

.Team .BgView img {
  object-fit: cover;
  object-position: top;
}

/* Cards list */
.Team .list-team {
  padding: 0;
  width: 50%;
  padding: 0 15px;
  margin: 0 auto;
  cursor: default;
}
.Team .list-team .member-team {
  margin-bottom: 65px;
  height: auto;
  border-bottom: 1px solid var(--divider-grey-color);
  padding-bottom: 15px;
  cursor: default;
}
.Team .list-team .member-team .part-1 img {
  width: 100px;
  height: 120px;
  position: relative;
  object-fit: cover;
}
.Team .list-team .member-team .part-2 h6,
.Team .list-team .member-team .part-2 p,
.Team .list-team .member-team .social {
  padding-left: 26px;
  text-transform: capitalize;
}
.Team .list-team .member-team .part-2 h6 {
  font-size: 1.4em;
  font-weight: 700;
  margin: 0;
  margin-top: 2px;
}
.Team .list-team .member-team .part-2 p {
  font-size: 0.8em;
  margin: 3px 0 16px;
  color: var(--grey-color);
}
.Team .list-team .member-team .social a svg {
  color: var(--grey-color);
  margin-right: 10px;
  transform: scale(1);
  transition: all 0.4s ease-in-out;
}
.Team .list-team .member-team .social a:hover svg {
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
}

.Team .list-team .member-team .part-3 {
  margin-top: 10px;
}
.Team .list-team .member-team .part-3 p,
.Team .list-team .member-team .part-3 .more-infos {
  margin: 0;
  color: var(--grey-color);
  font-size: 0.8em;
  padding-right: 20px;
}
.Team .list-team .member-team .part-3 .more-infos {
  text-transform: capitalize;
  color: var(--brouwn-color);
  padding-left: 26px;
  cursor: pointer;
}

/* Modal */
.modal .modal-dialog .modal-content .modal-body {
  cursor: default;
  padding: 0 30px 30px 30px;
}
.modal .modal-dialog .modal-content .modal-body .picture-part img {
  width: 100%;
  height: auto;
  position: relative;
  object-fit: cover;
}
.modal .modal-dialog .modal-content .modal-body .infos-part h4 {
  font-size: 2.4em;
  margin-bottom: 10px;
  text-transform: capitalize;
  width: 96%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal .modal-dialog .modal-content .modal-body .infos-part h6 {
  font-size: 1.4em;
  margin: 40px 0 10px 0;
  text-transform: capitalize;
}
.modal .modal-dialog .modal-content .modal-body .infos-part {
  font-size: 15px;
  padding: 0 15px;
  word-wrap: break-word;
}
.modal
  .modal-dialog
  .modal-content
  .modal-body
  .infos-part
  .intro-member
  p
  span {
  color: var(--brouwn-color);
}
.modal
  .modal-dialog
  .modal-content
  .modal-body
  .infos-part
  .education-member
  ul {
  margin: 0;
  padding: 0;
}
.modal
  .modal-dialog
  .modal-content
  .modal-body
  .infos-part
  .education-member
  ul
  li {
  margin-bottom: 16px;
}
.modal
  .modal-dialog
  .modal-content
  .modal-body
  .infos-part
  .education-member
  ul
  li:last-child {
  margin-bottom: 0;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Team {
    margin-bottom: 12vh;
  }
  .Team .list-team {
    width: 100%;
    padding: 0 15px;
  }

  .Team .list-team .member-team {
    margin-bottom: 50px;
  }
  .Team .list-team .member-team .part-1 img {
    width: 80px;
    height: 100px;
  }
  .Team .list-team .member-team .part-2 h6 {
    font-size: 1.2em;
  }
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .part-3 p {
    font-size: 0.6em;
  }
  .Team .list-team .member-team .part-2 h6,
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .social,
  .Team .list-team .member-team .part-3 .more-infos {
    padding-left: 48px;
  }
  .Team .list-team .pagination .next.pagination-item::after {
    transform: scale(4);
  }
  .Team .list-team .pagination .prev.pagination-item::before {
    transform: scale(4);
  }

  /* Modal */
  .modal .modal-dialog .modal-content .modal-body .infos-part h4 {
    font-size: 2.2em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part h6 {
    font-size: 1.4em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part {
    padding: 0 10px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Team {
    margin-bottom: 12vh;
  }
  .Team .list-team {
    width: 100%;
    padding: 0 20px;
  }

  .Team .list-team .member-team {
    margin-bottom: 50px;
  }
  .Team .list-team .member-team .part-1 img {
    width: 100px;
    height: 120px;
  }
  .Team .list-team .member-team .part-2 h6 {
    font-size: 1.5em;
  }
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .part-3 p {
    font-size: 0.9em;
  }
  .Team .list-team .member-team .part-2 h6,
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .social,
  .Team .list-team .member-team .part-3 .more-infos {
    padding-left: 35px;
  }
  .Team .list-team .pagination .next.pagination-item::after {
    transform: scale(4);
  }
  .Team .list-team .pagination .prev.pagination-item::before {
    transform: scale(4);
  }

  /* Modal */
  .modal .modal-dialog .modal-content .modal-body .infos-part h4 {
    font-size: 2.2em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part h6 {
    font-size: 1.4em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part {
    padding: 0 10px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Team {
    margin-bottom: 13vh;
  }
  .Team .list-team {
    width: 100%;
    padding: 0 30px;
  }

  .Team .list-team .member-team {
    margin-bottom: 50px;
  }
  .Team .list-team .member-team .part-1 img {
    width: 120px;
    height: 140px;
  }
  .Team .list-team .member-team .part-2 h6 {
    font-size: 1.6em;
  }
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .part-3 p {
    font-size: 0.95em;
  }
  .Team .list-team .member-team .part-2 h6,
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .social,
  .Team .list-team .member-team .part-3 .more-infos {
    padding-left: 20px;
  }
  .Team .list-team .pagination .next.pagination-item::after {
    transform: scale(4);
  }
  .Team .list-team .pagination .prev.pagination-item::before {
    transform: scale(4);
  }

  /* Modal */
  .modal .modal-dialog .modal-content .modal-body .infos-part h4 {
    font-size: 2.2em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part h6 {
    font-size: 1.4em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part {
    padding: 0 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Team {
    margin-bottom: 14vh;
  }
  .Team .list-team {
    width: 80%;
    padding: 0 15px;
  }

  .Team .list-team .member-team {
    margin-bottom: 50px;
  }
  .Team .list-team .member-team .part-1 img {
    width: 120px;
    height: 140px;
  }
  .Team .list-team .member-team .part-2 h6 {
    font-size: 1.6em;
  }
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .part-3 p {
    font-size: 0.95em;
  }
  .Team .list-team .member-team .part-2 h6,
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .social,
  .Team .list-team .member-team .part-3 .more-infos {
    padding-left: 20px;
  }
  .Team .list-team .pagination .next.pagination-item::after {
    transform: scale(4);
  }
  .Team .list-team .pagination .prev.pagination-item::before {
    transform: scale(4);
  }

  /* Modal */
  .modal .modal-dialog .modal-content .modal-body .infos-part h4 {
    font-size: 2.4em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part h6 {
    font-size: 1.4em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part {
    padding: 0 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .Team {
    margin-bottom: 15vh;
  }
  .Team .list-team {
    width: 50%;
    padding: 0 15px;
  }

  .Team .list-team .member-team {
    margin-bottom: 65px;
  }
  .Team .list-team .member-team .part-1 img {
    width: 100px;
    height: 120px;
  }
  .Team .list-team .member-team .part-2 h6 {
    font-size: 1.4em;
  }
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .part-3 p {
    font-size: 0.8em;
  }
  .Team .list-team .member-team .part-2 h6,
  .Team .list-team .member-team .part-2 p,
  .Team .list-team .member-team .social {
    padding-left: 26px;
  }
  .Team .list-team .pagination .next.pagination-item::after {
    transform: scale(5);
  }
  .Team .list-team .pagination .prev.pagination-item::before {
    transform: scale(5);
  }

  /* Modal */
  .modal .modal-dialog .modal-content .modal-body .infos-part h4 {
    font-size: 2.4em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part h6 {
    font-size: 1.4em;
  }
  .modal .modal-dialog .modal-content .modal-body .infos-part {
    padding: 0 15px;
  }
}
