.BodyPart {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BodyPart img {
  width: 90%;
  height: auto;
  object-fit: contain;
  text-align: center;
  padding: 80px 0;
}
.BodyPart .quote {
  border-left: 1px solid var(--red-color);
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0 20px 10px;
}
.BodyPart .quote svg {
  transform: rotate(180deg);
  color: var(--red-color);
}
.BodyPart .quote p {
  font-family: "Anton", "Arial", sans-serif;
  font-size: 1.2rem;
  margin-left: 3px;
  margin-bottom: 0;
}
