* {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Barlow", "Arial" sans-serif;
  font-size: 16px;
  color: var(--black-color);
  background-color: var(--white-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  background: rgba(245, 245, 245, 0);
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background: #393939;
  border-radius: 30px;
  transition: 0.2s ease-in-out;
}
::-webkit-scrollbar-thumb:hover {
  background: #000000;
  transition: 0.2s ease-in-out;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #39ff8500;
  background-color: rgba(255, 255, 255, 0);
}
::selection {
  background-color: #262626;
  color: #ffffff;
}

a,
a:hover,
a:visited,
a:active,
a:focus,
a:link {
  color: var(--black-color) !important;
  text-decoration: none !important;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.lazy-load-image-background.lazy-load-image-loaded {
  display: initial !important;
}

/* Pagination */
.pagination {
  margin-top: 70px !important;
}
.pagination .pagination-item {
  border: none !important;
  font-size: 1em;
}
.pagination .active.pagination-item {
  color: var(--brouwn-color);
  background-color: transparent;
  font-weight: 700;
}
.pagination .next.pagination-item::after {
  content: "\203A";
  transform: scale(5);
  margin: -2.5px 0 0 14px !important;
  color: var(--grey-color);
  opacity: 0.5;
}
.pagination .prev.pagination-item::before {
  content: "\2039";
  transform: scale(5);
  margin: -2.5px 14px 0 0 !important;
  color: var(--grey-color);
  opacity: 0.5;
}

/* Modal dialog */
.modal-90w {
  width: 90% !important;
  max-width: none !important;
}
.modal .modal-dialog .modal-content .modal-header {
  padding-top: 35px;
  padding-right: 40px;
}
.modal .modal-dialog .modal-content .modal-header button {
  transform: scale(1.3);
  transition: all 0.4s ease-in-out;
}
.modal .modal-dialog .modal-content .modal-header button:hover {
  transform: scale(1.4);
  transition: all 0.4s ease-in-out;
}
.modal {
  z-index: 99999999999999999999999999999999 !important;
}

/* transition: all .4s ease-in-out; */

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* body {
    border: 15px solid grey;
  } */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* body {
    border: 15px solid yellow;
  } */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* body {
    border: 15px solid green;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* body {
    border: 15px solid pink;
  } */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  /* body {
    border: 15px solid blue;
  } */
}

/* Mac and iPad resolutions */
@media only screen and (min-width: 2000px) {
  /* body {
    border: 15px solid black;
  } */
}
@media only screen and (min-width: 2500px) {
  /* body {
    border: 15px solid red;
  } */
}
@media only screen and (min-width: 2800px) {
  /* body {
    border: 15px solid orange;
  } */
}
@media only screen and (min-width: 5000px) {
  /* body {
    border: 15px solid yellow;
  } */
}
