.Gateway .row {
  width: 100% !important;
  height: auto;
  margin: 0 !important;
}
.Gateway .row > * {
  padding: 0 !important;
}

.Gateway .container-list {
  width: 50%;
  padding: 0 15px;
  margin: 0 auto;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Gateway .container-list {
    width: 100%;
    padding: 0 15px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Gateway .container-list {
    width: 100%;
    padding: 0 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Gateway .container-list {
    width: 100%;
    padding: 0 30px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Gateway .container-list {
    width: 80%;
    padding: 0 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .Gateway .container-list {
    width: 50%;
    padding: 0 15px;
  }
}
