.Error404 {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: default;
  padding: 40px;
}

.Error404 h1 {
  color: var(--red-color);
  font-family: "Anton", "Arial", sans-serif;
  font-size: 8rem;
  text-align: center;
}
.Error404 h6 {
  font-size: 26px;
  text-align: center;
}
.Error404 p {
  font-size: 15px;
  text-align: center;
}
.Error404 .ButtonSimple {
  margin-top: 60px;
}
.Error404 .ButtonSimple a,
.Error404 .ButtonSimple a:focus,
.Error404 .ButtonSimple a:active:focus {
  background-color: var(--brouwn-color);
  text-transform: capitalize;
  border-color: var(--brouwn-color);
  padding-left: 4rem;
  padding-right: 4rem;
}
.Error404 .ButtonSimple:hover a {
  border-color: var(--white-color);
}
