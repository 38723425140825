.Contact {
  width: 100%;
  height: auto;
  margin-bottom: 15vh;
}

.Contact .content {
  padding: 0 80px;
}
/* Contact Infos */
.Contact .content .infos,
.Contact .content .form {
  display: flex;
  flex-direction: column;
  justify-content: start-flex;
  align-items: start-flex;
  cursor: default;
}
.Contact .content .infos h4,
.Contact .content .infos h5,
.Contact .content .form h4 {
  font-weight: 600;
  font-size: 28px;
  color: var(--brouwn-color);
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
}
.Contact .content .infos h5 {
  margin-top: 30px;
}
.Contact .content .infos .coordinates,
.Contact .content .infos h5 {
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: var(--black-color);
  transition: all 0.4s ease-in-out;
}
.Contact .content .infos .coordinates li a {
  color: var(--black-color);
  transition: all 0.4s ease-in-out;
}
.Contact .content .infos .coordinates li a:hover {
  color: var(--brouwn-color) !important;
  transition: all 0.4s ease-in-out;
}
.Contact .content .infos .opening-hours {
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.02em;
}

/* Contact Form */
.Contact .content .form {
  margin-top: 0;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Contact {
    margin-bottom: 12vh;
  }
  .Contact .content {
    padding: 0 30px;
  }
  .Contact .content .form {
    margin-top: 40px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Contact {
    margin-bottom: 12vh;
  }
  .Contact .content {
    padding: 0 40px;
  }
  .Contact .content .form {
    margin-top: 40px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Contact {
    margin-bottom: 13vh;
  }
  .Contact .content {
    padding: 0 50px;
  }
  .Contact .content .form {
    margin-top: 40px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Contact {
    margin-bottom: 14vh;
  }
  .Contact .content {
    padding: 0 60px;
  }
  .Contact .content .form {
    margin-top: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .Contact {
    margin-bottom: 15vh;
  }
  .Contact .content {
    padding: 0 80px;
  }
  .Contact .content .form {
    margin-top: 0;
  }
}
