.ButtonSubmit {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ButtonSubmit button,
.ButtonSubmit button:active,
.ButtonSubmit button:focus,
.ButtonSubmit button:active:focus {
  border-radius: 0;
  background-color: var(--brouwn-color);
  padding: 8px 80px;
  text-align: center;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  font-family: "Barlow", "Arial" sans-serif;
  text-transform: capitalize;
  position: relative;
  border: 1.5px solid var(--brouwn-color);
  transition: all 0.4s ease-in-out;
  box-shadow: none;
}
.ButtonSubmit button:hover,
.ButtonSubmit button:hover:active,
.ButtonSubmit button:hover:focus,
.ButtonSubmit button:hover:active:focus {
  color: var(--black-color);
  background-color: var(--brouwn-color);
  border: 1.5px solid var(--white-color);
  transition: all 0.4s ease-in-out;
}

/* -------------- Responsive -------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
}
